import { pagesPath } from '@/generated/lib/$path'

export const unAuthorizedPages: Array<string> = [
  pagesPath.account.sign_in.$url().pathname,
  pagesPath.$404.$url().pathname,
]

export const disabledLayoutPage: Array<string> = [
  pagesPath.my.phoneLine.attentions.$url().pathname,
  ...unAuthorizedPages,
]

export const authorizedPagesMasterExceptStaff: Array<string> = [
  pagesPath.master.phone_lines.$url().pathname,
  pagesPath.master.centers.$url().pathname,
  pagesPath.master.halls.$url().pathname,
  pagesPath.master.areas.$url().pathname,
  pagesPath.master.regions.$url().pathname,
  pagesPath.master.ja_branches.$url().pathname,
  pagesPath.master.jas.$url().pathname,
  pagesPath.master.partners.$url().pathname,
  pagesPath.master.stores.$url().pathname,
  pagesPath.master.summary.$url().pathname,
]
