import aspida from '@aspida/axios'
import axios from 'axios'
import { NextPageContext } from 'next/dist/shared/lib/utils'
import { GetServerSidePropsContext, PreviewData } from 'next/types'
import nookies, { destroyCookie, setCookie } from 'nookies'
import { ParsedUrlQuery } from 'querystring'
import { QueryClient } from 'react-query'

import { User } from '@/generated/api/@types'
import api from '@/generated/api/$api'
import { pagesPath } from '@/generated/lib/$path'
import { EUserPermission } from '@/src/const/userPermission'
import { ADMINISTRATOR, CALL_CENTER_STAFF } from '@/src/const/userRole'

import { client, cookieErrorMessageExpires, cookieExpires, headerConfig } from '../config/clientApi'
import { authorizedPagesMasterExceptStaff, unAuthorizedPages } from '../const/path'
import { userIdResource } from '../const/resource'

export const resWriteHead = (
  ctx: NextPageContext | GetServerSidePropsContext<ParsedUrlQuery, PreviewData>,
  status: number,
  location: string
) => {
  const { res, req } = ctx
  if (!res || !req) return
  if (req.url === location) return
  res.writeHead(status, {
    location,
  })
  res.end()
}

export const isAuthenticated = async (ctx: NextPageContext, queryClient: QueryClient) => {
  const { res, req } = ctx
  const cookies = nookies.get(ctx)
  if (!req?.url) return
  try {
    if (unAuthorizedPages.includes(req.url)) return
    // 認証画面でtokenを持っていない場合はリダイレクト
    if (!unAuthorizedPages.includes(req.url) && !cookies?.token) throw new Error()

    setCookie(ctx, 'token', cookies?.token || '', cookieExpires)
    client.setToken(cookies.token)
    const result = await api(aspida(axios, headerConfig(cookies.token))).user.$get()
    queryClient.setQueryData(userIdResource.key, result || '')
  } catch (error: any) {
    queryClient.setQueryData('Snackbar', {
      severity: 'warning',
      message: '認証に失敗しました!',
    })
    resWriteHead(ctx, 302, '/account/sign-in')
  }
}

export const isAuthorizedAccess = (
  ctx: NextPageContext | GetServerSidePropsContext,
  queryClient?: QueryClient
) => {
  const myQueryClient = queryClient ?? new QueryClient()
  const user = myQueryClient.getQueryData<User>(userIdResource.key)
  const cookies = nookies.get(ctx)

  if (!user) return
  if (!ctx.req?.url) return

  if (cookies?.snackbarSeverity && cookies?.snackbarMessage) {
    myQueryClient.setQueryData('Snackbar', {
      severity: cookies.snackbarSeverity,
      message: cookies.snackbarMessage,
    })
    destroyCookie(ctx, 'snackbarSeverity')
    destroyCookie(ctx, 'snackbarMessage')
  }

  const urlToBeChecked =
    (ctx as NextPageContext)?.pathname ?? (ctx as GetServerSidePropsContext)?.resolvedUrl

  if (unAuthorizedPages.includes(urlToBeChecked)) return

  try {
    //スタッフ編集権限のみあるuserのみ/master/users系許可
    if (
      urlToBeChecked.startsWith(pagesPath.master.users.$url().pathname) &&
      !(
        user.user_role.edit_admin_users ||
        user.user_role.edit_call_center_users ||
        user.user_role.edit_partner_users ||
        user.user_role.edit_phone_line_admin_users ||
        user.user_role.edit_phone_line_users
      )
    ) {
      throw new Error()
    }
    //マスタ管理配下のスタッフ以外の項目系はedit_tasks=1のみ許可
    if (
      authorizedPagesMasterExceptStaff.includes(urlToBeChecked) &&
      user?.user_role.edit_tasks !== EUserPermission.READ_WRITE
    ) {
      throw new Error()
    }
    //引き継ぎ系はedit_handoversの権限がればアクセス可能
    if (
      urlToBeChecked.startsWith(pagesPath.call_center.handovers.$url().pathname) &&
      !user.user_role.edit_handovers
    ) {
      throw new Error()
    }
    //葬儀系はedit_funeralsの権限に応じて、画面アクセス可能。
    if (
      urlToBeChecked.startsWith(pagesPath.my.funerals.$url().pathname) &&
      !user.user_role.edit_funerals
    ) {
      throw new Error()
    }
    //業務edit_tasksの権限に応じて、画面アクセス可能
    if (
      urlToBeChecked.startsWith(pagesPath.my.tasks.$url().pathname) &&
      !user.user_role.edit_tasks
    ) {
      throw new Error()
    }
    if (
      urlToBeChecked.startsWith(pagesPath.my.tasks.pending_list.$url().pathname) &&
      user.user_role.id !== ADMINISTRATOR &&
      user.user_role.id !== CALL_CENTER_STAFF
    ) {
      throw new Error()
    }
  } catch (e) {
    setCookie(ctx, 'snackbarSeverity', 'error', cookieErrorMessageExpires)
    setCookie(
      ctx,
      'snackbarMessage',
      'アクセス不可です。ホームページに遷移します。',
      cookieErrorMessageExpires
    )
    resWriteHead(ctx, 302, '/')
  }
}
