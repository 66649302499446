export const pagesPath = {
  "$404": {
    $url: (url?: { hash?: string }) => ({ pathname: '/404' as const, hash: url?.hash })
  },
  "account": {
    "sign_in": {
      $url: (url?: { hash?: string }) => ({ pathname: '/account/sign-in' as const, hash: url?.hash })
    }
  },
  "call_center": {
    "handovers": {
      _handoverId: (handoverId: string | number) => ({
        "detail": {
          $url: (url?: { hash?: string }) => ({ pathname: '/call-center/handovers/[handoverId]/detail' as const, query: { handoverId }, hash: url?.hash })
        },
        "edit": {
          $url: (url?: { hash?: string }) => ({ pathname: '/call-center/handovers/[handoverId]/edit' as const, query: { handoverId }, hash: url?.hash })
        }
      }),
      $url: (url?: { hash?: string }) => ({ pathname: '/call-center/handovers' as const, hash: url?.hash })
    }
  },
  "master": {
    "areas": {
      _areaId: (areaId: string | number) => ({
        "detail": {
          $url: (url?: { hash?: string }) => ({ pathname: '/master/areas/[areaId]/detail' as const, query: { areaId }, hash: url?.hash })
        },
        "edit": {
          $url: (url?: { hash?: string }) => ({ pathname: '/master/areas/[areaId]/edit' as const, query: { areaId }, hash: url?.hash })
        },
        "subArea": {
          _subAreaId: (subAreaId: string | number) => ({
            "detail": {
              $url: (url?: { hash?: string }) => ({ pathname: '/master/areas/[areaId]/subArea/[subAreaId]/detail' as const, query: { areaId, subAreaId }, hash: url?.hash })
            },
            "edit": {
              $url: (url?: { hash?: string }) => ({ pathname: '/master/areas/[areaId]/subArea/[subAreaId]/edit' as const, query: { areaId, subAreaId }, hash: url?.hash })
            }
          })
        }
      }),
      $url: (url?: { hash?: string }) => ({ pathname: '/master/areas' as const, hash: url?.hash })
    },
    "centers": {
      _id: (id: string | number) => ({
        "detail": {
          $url: (url?: { hash?: string }) => ({ pathname: '/master/centers/[id]/detail' as const, query: { id }, hash: url?.hash })
        },
        "edit": {
          $url: (url?: { hash?: string }) => ({ pathname: '/master/centers/[id]/edit' as const, query: { id }, hash: url?.hash })
        },
        "hospitals": {
          _hospitalId: (hospitalId: string | number) => ({
            "detail": {
              $url: (url?: { hash?: string }) => ({ pathname: '/master/centers/[id]/hospitals/[hospitalId]/detail' as const, query: { id, hospitalId }, hash: url?.hash })
            },
            "edit": {
              $url: (url?: { hash?: string }) => ({ pathname: '/master/centers/[id]/hospitals/[hospitalId]/edit' as const, query: { id, hospitalId }, hash: url?.hash })
            }
          })
        },
        "mortuaries": {
          _mortuaryId: (mortuaryId: string | number) => ({
            "detail": {
              $url: (url?: { hash?: string }) => ({ pathname: '/master/centers/[id]/mortuaries/[mortuaryId]/detail' as const, query: { id, mortuaryId }, hash: url?.hash })
            },
            "edit": {
              $url: (url?: { hash?: string }) => ({ pathname: '/master/centers/[id]/mortuaries/[mortuaryId]/edit' as const, query: { id, mortuaryId }, hash: url?.hash })
            }
          })
        }
      }),
      $url: (url?: { hash?: string }) => ({ pathname: '/master/centers' as const, hash: url?.hash })
    },
    "halls": {
      _id: (id: string | number) => ({
        "detail": {
          $url: (url?: { hash?: string }) => ({ pathname: '/master/halls/[id]/detail' as const, query: { id }, hash: url?.hash })
        },
        "edit": {
          $url: (url?: { hash?: string }) => ({ pathname: '/master/halls/[id]/edit' as const, query: { id }, hash: url?.hash })
        }
      }),
      $url: (url?: { hash?: string }) => ({ pathname: '/master/halls' as const, hash: url?.hash })
    },
    "ja_branches": {
      _id: (id: string | number) => ({
        "detail": {
          $url: (url?: { hash?: string }) => ({ pathname: '/master/ja-branches/[id]/detail' as const, query: { id }, hash: url?.hash })
        },
        "edit": {
          $url: (url?: { hash?: string }) => ({ pathname: '/master/ja-branches/[id]/edit' as const, query: { id }, hash: url?.hash })
        }
      }),
      $url: (url?: { hash?: string }) => ({ pathname: '/master/ja-branches' as const, hash: url?.hash })
    },
    "jas": {
      _id: (id: string | number) => ({
        "detail": {
          $url: (url?: { hash?: string }) => ({ pathname: '/master/jas/[id]/detail' as const, query: { id }, hash: url?.hash })
        },
        "edit": {
          $url: (url?: { hash?: string }) => ({ pathname: '/master/jas/[id]/edit' as const, query: { id }, hash: url?.hash })
        }
      }),
      $url: (url?: { hash?: string }) => ({ pathname: '/master/jas' as const, hash: url?.hash })
    },
    "partners": {
      _id: (id: string | number) => ({
        "detail": {
          $url: (url?: { hash?: string }) => ({ pathname: '/master/partners/[id]/detail' as const, query: { id }, hash: url?.hash })
        },
        "edit": {
          $url: (url?: { hash?: string }) => ({ pathname: '/master/partners/[id]/edit' as const, query: { id }, hash: url?.hash })
        }
      }),
      $url: (url?: { hash?: string }) => ({ pathname: '/master/partners' as const, hash: url?.hash })
    },
    "phone_lines": {
      _id: (id: string | number) => ({
        "areas": {
          _areaId: (areaId: string | number) => ({
            "edit": {
              $url: (url?: { hash?: string }) => ({ pathname: '/master/phone_lines/[id]/areas/[areaId]/edit' as const, query: { id, areaId }, hash: url?.hash })
            }
          })
        },
        "attentions": {
          _attentionId: (attentionId: string | number) => ({
            "edit": {
              $url: (url?: { hash?: string }) => ({ pathname: '/master/phone_lines/[id]/attentions/[attentionId]/edit' as const, query: { id, attentionId }, hash: url?.hash })
            }
          })
        },
        "call_system_memos": {
          _callSystemMemoId: (callSystemMemoId: string | number) => ({
            "edit": {
              $url: (url?: { hash?: string }) => ({ pathname: '/master/phone_lines/[id]/call-system-memos/[callSystemMemoId]/edit' as const, query: { id, callSystemMemoId }, hash: url?.hash })
            }
          })
        },
        "carrier_conditions": {
          _carrierConditionId: (carrierConditionId: string | number) => ({
            "edit": {
              $url: (url?: { hash?: string }) => ({ pathname: '/master/phone_lines/[id]/carrier-conditions/[carrierConditionId]/edit' as const, query: { id, carrierConditionId }, hash: url?.hash })
            }
          }),
          $url: (url?: { hash?: string }) => ({ pathname: '/master/phone_lines/[id]/carrier-conditions' as const, query: { id }, hash: url?.hash })
        },
        "carriers": {
          _carrierId: (carrierId: string | number) => ({
            "edit": {
              $url: (url?: { hash?: string }) => ({ pathname: '/master/phone_lines/[id]/carriers/[carrierId]/edit' as const, query: { id, carrierId }, hash: url?.hash })
            }
          })
        },
        "detail": {
          $url: (url?: { hash?: string }) => ({ pathname: '/master/phone_lines/[id]/detail' as const, query: { id }, hash: url?.hash })
        },
        "edit": {
          $url: (url?: { hash?: string }) => ({ pathname: '/master/phone_lines/[id]/edit' as const, query: { id }, hash: url?.hash })
        },
        "manuals": {
          $url: (url?: { hash?: string }) => ({ pathname: '/master/phone_lines/[id]/manuals' as const, query: { id }, hash: url?.hash })
        },
        "phone_line_partners": {
          _phoneLinePartnersId: (phoneLinePartnersId: string | number) => ({
            "edit": {
              $url: (url?: { hash?: string }) => ({ pathname: '/master/phone_lines/[id]/phone_line_partners/[phoneLinePartnersId]/edit' as const, query: { id, phoneLinePartnersId }, hash: url?.hash })
            }
          })
        },
        "report_destinations": {
          _reportDestinationId: (reportDestinationId: string | number) => ({
            "edit": {
              $url: (url?: { hash?: string }) => ({ pathname: '/master/phone_lines/[id]/report-destinations/[reportDestinationId]/edit' as const, query: { id, reportDestinationId }, hash: url?.hash })
            }
          })
        }
      }),
      $url: (url?: { hash?: string }) => ({ pathname: '/master/phone_lines' as const, hash: url?.hash })
    },
    "regions": {
      _id: (id: string | number) => ({
        "detail": {
          $url: (url?: { hash?: string }) => ({ pathname: '/master/regions/[id]/detail' as const, query: { id }, hash: url?.hash })
        },
        "edit": {
          $url: (url?: { hash?: string }) => ({ pathname: '/master/regions/[id]/edit' as const, query: { id }, hash: url?.hash })
        }
      }),
      $url: (url?: { hash?: string }) => ({ pathname: '/master/regions' as const, hash: url?.hash })
    },
    "stores": {
      _id: (id: string | number) => ({
        "detail": {
          $url: (url?: { hash?: string }) => ({ pathname: '/master/stores/[id]/detail' as const, query: { id }, hash: url?.hash })
        },
        "edit": {
          $url: (url?: { hash?: string }) => ({ pathname: '/master/stores/[id]/edit' as const, query: { id }, hash: url?.hash })
        }
      }),
      $url: (url?: { hash?: string }) => ({ pathname: '/master/stores' as const, hash: url?.hash })
    },
    "summary": {
      $url: (url?: { hash?: string }) => ({ pathname: '/master/summary' as const, hash: url?.hash })
    },
    "users": {
      _id: (id: string | number) => ({
        "detail": {
          $url: (url?: { hash?: string }) => ({ pathname: '/master/users/[id]/detail' as const, query: { id }, hash: url?.hash })
        },
        "edit": {
          $url: (url?: { hash?: string }) => ({ pathname: '/master/users/[id]/edit' as const, query: { id }, hash: url?.hash })
        }
      }),
      $url: (url?: { hash?: string }) => ({ pathname: '/master/users' as const, hash: url?.hash })
    }
  },
  "my": {
    "funerals": {
      _funeralId: (funeralId: string | number) => ({
        "confirm": {
          $url: (url?: { hash?: string }) => ({ pathname: '/my/funerals/[funeralId]/confirm' as const, query: { funeralId }, hash: url?.hash })
        },
        "detail": {
          $url: (url?: { hash?: string }) => ({ pathname: '/my/funerals/[funeralId]/detail' as const, query: { funeralId }, hash: url?.hash })
        },
        "edit": {
          $url: (url?: { hash?: string }) => ({ pathname: '/my/funerals/[funeralId]/edit' as const, query: { funeralId }, hash: url?.hash })
        }
      }),
      $url: (url?: { hash?: string }) => ({ pathname: '/my/funerals' as const, hash: url?.hash })
    },
    "phoneLine": {
      "attentions": {
        $url: (url?: { hash?: string }) => ({ pathname: '/my/phoneLine/attentions' as const, hash: url?.hash })
      }
    },
    "tasks": {
      "contacts": {
        _taskId: (taskId: string | number) => ({
          "confirm": {
            $url: (url?: { hash?: string }) => ({ pathname: '/my/tasks/contacts/[taskId]/confirm' as const, query: { taskId }, hash: url?.hash })
          },
          "detail": {
            $url: (url?: { hash?: string }) => ({ pathname: '/my/tasks/contacts/[taskId]/detail' as const, query: { taskId }, hash: url?.hash })
          },
          "edit": {
            $url: (url?: { hash?: string }) => ({ pathname: '/my/tasks/contacts/[taskId]/edit' as const, query: { taskId }, hash: url?.hash })
          }
        })
      },
      "new": {
        $url: (url?: { hash?: string }) => ({ pathname: '/my/tasks/new' as const, hash: url?.hash })
      },
      "orders": {
        _taskId: (taskId: string | number) => ({
          "confirm": {
            $url: (url?: { hash?: string }) => ({ pathname: '/my/tasks/orders/[taskId]/confirm' as const, query: { taskId }, hash: url?.hash })
          },
          "detail": {
            $url: (url?: { hash?: string }) => ({ pathname: '/my/tasks/orders/[taskId]/detail' as const, query: { taskId }, hash: url?.hash })
          },
          "edit": {
            $url: (url?: { hash?: string }) => ({ pathname: '/my/tasks/orders/[taskId]/edit' as const, query: { taskId }, hash: url?.hash })
          }
        })
      },
      "pending_list": {
        $url: (url?: { hash?: string }) => ({ pathname: '/my/tasks/pending-list' as const, hash: url?.hash })
      },
      "receptions": {
        _taskId: (taskId: string | number) => ({
          "confirm": {
            $url: (url?: { hash?: string }) => ({ pathname: '/my/tasks/receptions/[taskId]/confirm' as const, query: { taskId }, hash: url?.hash })
          },
          "detail": {
            $url: (url?: { hash?: string }) => ({ pathname: '/my/tasks/receptions/[taskId]/detail' as const, query: { taskId }, hash: url?.hash })
          },
          "edit": {
            $url: (url?: { hash?: string }) => ({ pathname: '/my/tasks/receptions/[taskId]/edit' as const, query: { taskId }, hash: url?.hash })
          }
        })
      },
      "transports": {
        _taskId: (taskId: string | number) => ({
          "confirm": {
            $url: (url?: { hash?: string }) => ({ pathname: '/my/tasks/transports/[taskId]/confirm' as const, query: { taskId }, hash: url?.hash })
          },
          "detail": {
            $url: (url?: { hash?: string }) => ({ pathname: '/my/tasks/transports/[taskId]/detail' as const, query: { taskId }, hash: url?.hash })
          },
          "edit": {
            $url: (url?: { hash?: string }) => ({ pathname: '/my/tasks/transports/[taskId]/edit' as const, query: { taskId }, hash: url?.hash })
          }
        })
      },
      $url: (url?: { hash?: string }) => ({ pathname: '/my/tasks' as const, hash: url?.hash })
    }
  },
  $url: (url?: { hash?: string }) => ({ pathname: '/' as const, hash: url?.hash })
}

export type PagesPath = typeof pagesPath
