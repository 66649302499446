import { Box, CssBaseline, useMediaQuery } from '@mui/material'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useState } from 'react'

import MwFooter from '@/components/common/MwFooter'
import MwSideNav from '@/components/common/MwSideNav'
import MwTopNav from '@/components/common/MwTopNav'
import { disabledLayoutPage } from '@/src/const/path'
import useSSR from '@/src/hooks/useSSR'

type Props = {
  children: React.ReactElement
}

export const drawerWidth = 250

const MwLayout: React.FC<Props> = ({ children }) => {
  const router = useRouter()
  const match = useMediaQuery('(max-width:375px)')
  const { isBrowser } = useSSR()

  const [mobileOpen, setMobileOpen] = useState(match)
  const [mounted, setMounted] = useState(false)

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen(!mobileOpen)
  }, [mobileOpen])

  useEffect(() => {
    setMounted(true)
  }, [setMounted])

  const isDisabledLayout = useCallback(() => {
    if (isBrowser) {
      return disabledLayoutPage.includes(router.pathname)
    }
    return false
  }, [isBrowser, router.pathname])

  if (!mounted) return <></>
  if (isDisabledLayout()) return <>{children}</>
  return (
    <Box sx={{ display: 'flex', width: { md: `calc(100% - ${drawerWidth}px)` } }}>
      <CssBaseline />
      <MwTopNav
        isMaxWidth={match}
        drawerWidth={drawerWidth}
        handleDrawerToggle={handleDrawerToggle}
      />
      <MwSideNav
        mobileOpen={mobileOpen}
        drawerWidth={drawerWidth}
        handleDrawerToggle={handleDrawerToggle}
      >
        <div id="main">
          {children}
          <MwFooter />
        </div>
      </MwSideNav>
    </Box>
  )
}

export default MwLayout
