import {
  Button,
  Collapse,
  css,
  Divider,
  Drawer,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from '@mui/material'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'

import { pagesPath } from '@/generated/lib/$path'
import { MenuItemType, navMenuItems } from '@/model/menuItems'
import { EUserPermission } from '@/src/const/userPermission'
import { useLoginUser } from '@/src/hooks/api/useLoginUser'

type Props = {
  window?: () => Window
  children: React.ReactElement
  drawerWidth: number
  handleDrawerToggle: () => void
  mobileOpen: boolean
}

const styles = {
  container: css`
    padding: 12px;
    margin: 100px 0 20px 0;
  `,
  addNewWork: css`
    padding: 16px;
    background: #cd853f;
    :hover {
      background: #aa6a2c;
    }
  `,
  addNewSougi: css`
    padding: 16px;
    margin: 20px 0 0 0;
    color: white;
    background: #980036;
    :hover {
      background: #7a0026;
    }
  `,
}

const ItemLink: React.FC<Pick<MenuItemType, 'label' | 'icon' | 'href' | 'onClick'>> = ({
  label,
  icon,
  href,
  onClick,
}) => {
  return (
    <Link key={label} href={href || '/'} passHref>
      <ListItemButton component="a" onClick={onClick}>
        <ListItemIcon>{icon || <></>}</ListItemIcon>
        <ListItemText primary={label} />
      </ListItemButton>
    </Link>
  )
}

const SideMenu: React.FC = () => {
  const { loginUser } = useLoginUser()
  const router = useRouter()
  if (!loginUser) {
    return null
  }

  return (
    <div>
      <div css={styles.container}>
        {loginUser.user_role.edit_tasks === EUserPermission.READ_WRITE ? (
          <Link href={pagesPath.my.tasks.new.$url()}>
            <Button variant="contained" css={styles.addNewWork} fullWidth size="large">
              <Typography variant="h6">新規 業務登録</Typography>
            </Button>
          </Link>
        ) : null}
        {[EUserPermission.READ_WRITE, EUserPermission.LOCAL_READ_WRITE].includes(
          loginUser.user_role.edit_funerals
        ) ? (
          <Link href={pagesPath.my.funerals._funeralId('new').edit.$url()} passHref>
            <Button
              variant="contained"
              css={styles.addNewSougi}
              href="/my/tasks/new/select-area"
              fullWidth
              size="large"
            >
              <Typography variant="h6">新規 葬儀登録</Typography>
            </Button>
          </Link>
        ) : null}
      </div>
      <Divider />
      <List>
        {navMenuItems
          .filter((item) => (typeof item.show === 'boolean' ? item.show : item.show(loginUser)))
          .map((menuItem, index) => (
            <React.Fragment key={index}>
              <ItemLink
                icon={menuItem.icon}
                label={menuItem.label}
                href={menuItem.href}
                onClick={() => {
                  //同じURLをLoadする場合、初期化してReload
                  if (router.query?.queryString && router.pathname === menuItem.href) {
                    router.push('/')
                    router.push(menuItem.href, { query: {} })
                  }
                }}
              />
              {menuItem.subMenu && (
                <Collapse in timeout="auto">
                  {menuItem.subMenu
                    .filter((item) =>
                      typeof item.show === 'boolean' ? item.show : item.show(loginUser)
                    )
                    .map(({ label, href, show }, index) => (
                      <ItemLink
                        key={index}
                        label={label}
                        href={href}
                        onClick={() => {
                          //同じURLをLoadする場合、初期化してReload
                          if (router.query?.queryString && router.pathname === href) {
                            router.push('/')
                            router.push(href, { query: {} })
                          }
                        }}
                      />
                    ))}
                </Collapse>
              )}
            </React.Fragment>
          ))}
      </List>
      <Divider />
    </div>
  )
}

const MwSideNav: React.FC<Props> = ({
  mobileOpen,
  drawerWidth,
  window,
  children,
  handleDrawerToggle,
}) => {
  const container = window !== undefined ? () => window().document.body : undefined
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {}, [isMobile])

  return (
    <>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant={isMobile ? 'temporary' : 'permanent'}
          open={isMobile ? mobileOpen : false}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: isMobile ? true : false,
          }}
          sx={{
            display: isMobile ? { xs: 'block', md: 'none' } : { xs: 'none', md: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          onClick={handleDrawerToggle}
        >
          <SideMenu />
        </Drawer>
      </Box>
      <Grid
        container
        mt={8}
        css={css`
          background: whitesmoke;
        `}
      >
        {children}
      </Grid>
    </>
  )
}

export default MwSideNav
