/** @var int 管理者 */
export const ADMINISTRATOR = 1

/** @var int コールセンタースタッフ */
export const CALL_CENTER_STAFF = 2

/** @var int 契約社 */
export const PARTNER_STAFF = 3

/** @var int 葬祭スタッフ管理者 */
export const FUNERAL_CENTER_ADMINISTRATOR = 4

/** @var int 葬祭スタッフ */
export const FUNERAL_CENTER_STAFF = 5
