import { Alert, css } from '@mui/material'
import { AxiosError } from 'axios'
import router from 'next/router'
import React, { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'

import { ERROR_HANDLE_KEY } from '@/src/config/reactQueryConfig'
import { convertErrorMessageToUserReadables } from '@/src/utils/convertErrorMessage'

type Props = {
  children: React.ReactNode
}

const ErrorHandler: React.FC<Props> = ({ children }) => {
  const ref = React.createRef<HTMLDivElement>()
  const client = useQueryClient()
  const { data } = useQuery(
    ERROR_HANDLE_KEY,
    () =>
      client.getQueryData<
        AxiosError<{ message: string; errors?: { [name: string]: Array<string> } }>
      >(ERROR_HANDLE_KEY),
    {
      staleTime: 1000,
    }
  )
  const [error, setError] = useState<string | Array<string>>('')

  useEffect(() => {
    if (data) {
      if (data.status === '401') {
        if (router.pathname !== '/account/sign-in') {
          router.push('/account/sign-in')
        }
        return
      }
      if (data.response?.data?.errors) {
        setError(convertErrorMessageToUserReadables(data.response.data.errors))
        return
      }
      if (typeof data.response?.data?.message === 'string') {
        if (data.response.data.message.startsWith('SQLSTATE[23000]')) {
          setError('この葬儀に紐付く注文があるため削除できません。')
          return
        }
        setError(data.response.data.message)
        return
      }
      if (data.message) {
        setError(data.message)
        return
      }
      setError(JSON.stringify(data))
    } else {
      setError('')
    }
  }, [data, setError])

  useEffect(() => {
    if (error) {
      setTimeout(() => ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' }), 0)
    }
  }, [error, ref])

  return (
    <>
      {error &&
        (typeof error == 'string' ? (
          <Alert
            ref={ref}
            css={css`
              width: 100%;
            `}
            severity="error"
          >
            {error}
          </Alert>
        ) : (
          (error as Array<string>).map((e, i) => (
            <Alert
              key={i}
              ref={ref}
              css={css`
                width: 100%;
              `}
              severity="error"
            >
              {e}
            </Alert>
          ))
        ))}
      {children}
    </>
  )
}

export default ErrorHandler
