export const convertErrorMessageToUserReadables = (errors: { [name: string]: Array<string> }) =>
  Object.entries(errors).map(
    (prop) =>
      optionalPropertyNameConversion(prop[0]) +
      prop[1].map((m) => optionalPropertyValueConversion(m)).join(' ')
  ) ?? 'エラーが発生しました。'

const optionalPropertyNameConversion = (original: string) => {
  switch (original) {
    case 'login_name':
      return '【ログインID】'
    case 'user_role_id':
      return '【権限】'
    default:
      return original
  }
}

const optionalPropertyValueConversion = (original: string) => {
  switch (original) {
    case 'login nameの値は既に存在しています。':
      return '既に使用済みのログインIDです。別のログインIDを指定してください。'
    case 'user role idは必ず指定してください。':
      return '権限は必ず指定してください。'
    default:
      return original
  }
}
