import '@/styles/globals.css'

import { ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import type { AppProps } from 'next/app'
import { AppContextType, NextComponentType } from 'next/dist/shared/lib/utils'
import Head from 'next/head'
import React from 'react'
import { dehydrate, Hydrate, QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import MwLayout from '@/components/common/MwLayout'
import MwSnackbar from '@/components/common/MwSnackbar'
import ErrorHandler from '@/components/modules/ErrorHandler'
import { theme } from '@/materialTheme'
import queryClientConfig from '@/src/config/reactQueryConfig'
import { QueryResourceType } from '@/src/const/resource'
import { isAuthenticated, isAuthorizedAccess } from '@/src/utils/checkAuth'

const MyApp = ({ Component, pageProps }: AppProps<{ dehydratedState: any }>) => {
  if (typeof window === 'undefined') React.useLayoutEffect = () => {}
  return (
    <>
      {/* _document.tsxにHeadを置くとwarningが出る */}
      <Head>
        <title>葬祭業務支援システム</title>
        {/* eslint-disable-next-line @next/next/no-css-tags */}
      </Head>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
          <QueryClientProvider client={queryClientConfig}>
            <Hydrate state={pageProps.dehydratedState}>
              <ReactQueryDevtools initialIsOpen={false} />
              <MwLayout>
                <ErrorHandler>
                  <Component {...pageProps} />
                </ErrorHandler>
              </MwLayout>
              <MwSnackbar />
            </Hydrate>
          </QueryClientProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </>
  )
}

MyApp.getInitialProps = async ({ ctx, Component }: AppContextType) => {
  const queryClient = new QueryClient()
  await isAuthenticated(ctx, queryClient)
  isAuthorizedAccess(ctx, queryClient)

  try {
    const { getInitialProps, requiredResource } = Component as NextComponentType & {
      requiredResource?: Array<QueryResourceType>
    }

    if (getInitialProps) {
      await getInitialProps(ctx)
    }

    if (requiredResource) {
      await Promise.all(
        requiredResource?.map(async (resource: QueryResourceType) => {
          await queryClient.fetchQuery(resource.key, resource.fetcher)
        })
      )
    }
  } catch (error: any) {
    queryClient.setQueryData('Snackbar', {
      severity: 'warning',
      message: error?.message,
    })
  }

  return {
    pageProps: {
      dehydratedState: dehydrate(queryClient),
    },
  }
}

export default MyApp
