/**
 * ユーザー権限
 * 0:None,
 * 1:Read/Write,
 * 2:Read,
 * 3:自分の所属内のRead/Write,
 * 4:自分の所属内のRead
 * */
export enum EUserPermission {
  NONE = 0,
  READ_WRITE = 1,
  READ = 2,
  LOCAL_READ_WRITE = 3,
  LOCAL_READ = 4,
}
