import type { AspidaClient, BasicHeaders } from 'aspida'
import { dataToURLString } from 'aspida'

import type { Methods as Methods0 } from './call-center/handovers'
import type { Methods as Methods1 } from './call-center/handovers/_handover_id@number'
import type { Methods as Methods2 } from './cloudfront/pre_signed_url'
import type { Methods as Methods3 } from './excel/funerals'
import type { Methods as Methods4 } from './excel/master/areas'
import type { Methods as Methods5 } from './excel/master/carrier_conditions'
import type { Methods as Methods6 } from './excel/master/centers'
import type { Methods as Methods7 } from './excel/master/halls'
import type { Methods as Methods8 } from './excel/master/hospitals'
import type { Methods as Methods9 } from './excel/master/japan_agricultural_branches'
import type { Methods as Methods10 } from './excel/master/japan_agriculturals'
import type { Methods as Methods11 } from './excel/master/mortuaries'
import type { Methods as Methods12 } from './excel/master/partners'
import type { Methods as Methods13 } from './excel/master/phone_lines'
import type { Methods as Methods14 } from './excel/master/regions'
import type { Methods as Methods15 } from './excel/master/stores'
import type { Methods as Methods16 } from './excel/summary'
import type { Methods as Methods17 } from './excel/tasks'
import type { Methods as Methods18 } from './excel/tasks/_task_id@number'
import type { Methods as Methods19 } from './funerals'
import type { Methods as Methods20 } from './funerals/_funeral_id@number'
import type { Methods as Methods21 } from './login'
import type { Methods as Methods22 } from './master/areas'
import type { Methods as Methods23 } from './master/areas/_area_id@number'
import type { Methods as Methods24 } from './master/areas/_area_id@number/subareas'
import type { Methods as Methods25 } from './master/areas/_area_id@number/subareas/_subarea_id@number'
import type { Methods as Methods26 } from './master/centers'
import type { Methods as Methods27 } from './master/centers/_center_id@number'
import type { Methods as Methods28 } from './master/centers/_center_id@number/hospitals'
import type { Methods as Methods29 } from './master/centers/_center_id@number/hospitals/_hospital_id@number'
import type { Methods as Methods30 } from './master/centers/_center_id@number/mortuaries'
import type { Methods as Methods31 } from './master/centers/_center_id@number/mortuaries/_mortuary_id@string'
import type { Methods as Methods32 } from './master/centers/_center_id@number/stores'
import type { Methods as Methods33 } from './master/halls'
import type { Methods as Methods34 } from './master/halls/_hall_id@number'
import type { Methods as Methods35 } from './master/japan_agricultural_branches'
import type { Methods as Methods36 } from './master/japan_agricultural_branches/_japan_agricultural_branch_id@number'
import type { Methods as Methods37 } from './master/japan_agriculturals'
import type { Methods as Methods38 } from './master/japan_agriculturals/_japan_agricultural_id@number'
import type { Methods as Methods39 } from './master/partners'
import type { Methods as Methods40 } from './master/partners/_partner_id@number'
import type { Methods as Methods41 } from './master/partners/_partner_id@number/phone_lines'
import type { Methods as Methods42 } from './master/phone_lines'
import type { Methods as Methods43 } from './master/phone_lines/_phone_line_id@number'
import type { Methods as Methods44 } from './master/phone_lines/_phone_line_id@number/areas'
import type { Methods as Methods45 } from './master/phone_lines/_phone_line_id@number/attentions'
import type { Methods as Methods46 } from './master/phone_lines/_phone_line_id@number/attentions/_attention_id@number'
import type { Methods as Methods47 } from './master/phone_lines/_phone_line_id@number/call_system_memos'
import type { Methods as Methods48 } from './master/phone_lines/_phone_line_id@number/call_system_memos/_call_system_memo_id@number'
import type { Methods as Methods49 } from './master/phone_lines/_phone_line_id@number/carrier_conditions'
import type { Methods as Methods50 } from './master/phone_lines/_phone_line_id@number/carrier_conditions/_carrier_condition_id@number'
import type { Methods as Methods51 } from './master/phone_lines/_phone_line_id@number/carrier_conditions/_carrier_condition_id@number/update_display_order'
import type { Methods as Methods52 } from './master/phone_lines/_phone_line_id@number/carriers'
import type { Methods as Methods53 } from './master/phone_lines/_phone_line_id@number/carriers/_carrier_id@number'
import type { Methods as Methods54 } from './master/phone_lines/_phone_line_id@number/halls'
import type { Methods as Methods55 } from './master/phone_lines/_phone_line_id@number/japan_agriculturals'
import type { Methods as Methods56 } from './master/phone_lines/_phone_line_id@number/japan_agriculturals/_japan_agricultural_id@number/japan_agricultural_branches'
import type { Methods as Methods57 } from './master/phone_lines/_phone_line_id@number/manuals'
import type { Methods as Methods58 } from './master/phone_lines/_phone_line_id@number/manuals/_manual_id@number'
import type { Methods as Methods59 } from './master/phone_lines/_phone_line_id@number/mortuaries'
import type { Methods as Methods60 } from './master/phone_lines/_phone_line_id@number/phone_line_partners'
import type { Methods as Methods61 } from './master/phone_lines/_phone_line_id@number/phone_line_partners/_phone_line_partner_id@number'
import type { Methods as Methods62 } from './master/phone_lines/_phone_line_id@number/report_destinations'
import type { Methods as Methods63 } from './master/phone_lines/_phone_line_id@number/report_destinations/_report_destination_id@number'
import type { Methods as Methods64 } from './master/phone_lines/_phone_line_id@number/stores'
import type { Methods as Methods65 } from './master/regions'
import type { Methods as Methods66 } from './master/regions/_region_id@number'
import type { Methods as Methods67 } from './master/stores'
import type { Methods as Methods68 } from './master/stores/_store_id@number'
import type { Methods as Methods69 } from './pdf/tasks/_task_id@number'
import type { Methods as Methods70 } from './tasks'
import type { Methods as Methods71 } from './tasks/_task_id@number'
import type { Methods as Methods72 } from './user'
import type { Methods as Methods73 } from './user-roles'
import type { Methods as Methods74 } from './users'
import type { Methods as Methods75 } from './users/_user_id@number'

const api = <T>({ baseURL, fetch }: AspidaClient<T>) => {
  const prefix = (baseURL === undefined ? 'https://api.sosai-sys.com/api' : baseURL).replace(/\/$/, '')
  const PATH0 = '/call-center/handovers'
  const PATH1 = '/cloudfront/pre_signed_url'
  const PATH2 = '/excel/funerals'
  const PATH3 = '/excel/master/areas'
  const PATH4 = '/excel/master/carrier_conditions'
  const PATH5 = '/excel/master/centers'
  const PATH6 = '/excel/master/halls'
  const PATH7 = '/excel/master/hospitals'
  const PATH8 = '/excel/master/japan_agricultural_branches'
  const PATH9 = '/excel/master/japan_agriculturals'
  const PATH10 = '/excel/master/mortuaries'
  const PATH11 = '/excel/master/partners'
  const PATH12 = '/excel/master/phone_lines'
  const PATH13 = '/excel/master/regions'
  const PATH14 = '/excel/master/stores'
  const PATH15 = '/excel/summary'
  const PATH16 = '/excel/tasks'
  const PATH17 = '/funerals'
  const PATH18 = '/login'
  const PATH19 = '/master/areas'
  const PATH20 = '/subareas'
  const PATH21 = '/master/centers'
  const PATH22 = '/hospitals'
  const PATH23 = '/mortuaries'
  const PATH24 = '/stores'
  const PATH25 = '/master/halls'
  const PATH26 = '/master/japan_agricultural_branches'
  const PATH27 = '/master/japan_agriculturals'
  const PATH28 = '/master/partners'
  const PATH29 = '/phone_lines'
  const PATH30 = '/master/phone_lines'
  const PATH31 = '/areas'
  const PATH32 = '/attentions'
  const PATH33 = '/call_system_memos'
  const PATH34 = '/carrier_conditions'
  const PATH35 = '/update_display_order'
  const PATH36 = '/carriers'
  const PATH37 = '/halls'
  const PATH38 = '/japan_agriculturals'
  const PATH39 = '/japan_agricultural_branches'
  const PATH40 = '/manuals'
  const PATH41 = '/phone_line_partners'
  const PATH42 = '/report_destinations'
  const PATH43 = '/master/regions'
  const PATH44 = '/master/stores'
  const PATH45 = '/pdf/tasks'
  const PATH46 = '/tasks'
  const PATH47 = '/user'
  const PATH48 = '/user-roles'
  const PATH49 = '/users'
  const GET = 'GET'
  const POST = 'POST'
  const PUT = 'PUT'
  const DELETE = 'DELETE'

  return {
    call_center: {
      handovers: {
        _handover_id: (val2: number) => {
          const prefix2 = `${PATH0}/${val2}`

          return {
            /**
             * 引き継ぎの編集を行う
             */
            put: (option: { body: Methods1['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods1['put']['status']>(prefix, prefix2, PUT, option).send(),
            /**
             * 引き継ぎの編集を行う
             */
            $put: (option: { body: Methods1['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods1['put']['status']>(prefix, prefix2, PUT, option).send().then(r => r.body),
            /**
             * 引き継ぎ詳細取得を行う
             * @returns 引き継ぎ詳細取得成功
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods1['get']['resBody'], BasicHeaders, Methods1['get']['status']>(prefix, prefix2, GET, option).json(),
            /**
             * 引き継ぎ詳細取得を行う
             * @returns 引き継ぎ詳細取得成功
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods1['get']['resBody'], BasicHeaders, Methods1['get']['status']>(prefix, prefix2, GET, option).json().then(r => r.body),
            /**
             * 引き継ぎの削除を行う
             */
            delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<void, BasicHeaders, Methods1['delete']['status']>(prefix, prefix2, DELETE, option).send(),
            /**
             * 引き継ぎの削除を行う
             */
            $delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<void, BasicHeaders, Methods1['delete']['status']>(prefix, prefix2, DELETE, option).send().then(r => r.body),
            $path: () => `${prefix}${prefix2}`
          }
        },
        /**
         * 引き継ぎの登録を行う
         * @returns Created
         */
        post: (option: { body: Methods0['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods0['post']['resBody'], BasicHeaders, Methods0['post']['status']>(prefix, PATH0, POST, option).json(),
        /**
         * 引き継ぎの登録を行う
         * @returns Created
         */
        $post: (option: { body: Methods0['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods0['post']['resBody'], BasicHeaders, Methods0['post']['status']>(prefix, PATH0, POST, option).json().then(r => r.body),
        /**
         * 引き継ぎ一覧取得を行う。
         * @returns OK
         */
        get: (option: { query: Methods0['get']['query'], config?: T | undefined }) =>
          fetch<Methods0['get']['resBody'], BasicHeaders, Methods0['get']['status']>(prefix, PATH0, GET, option).json(),
        /**
         * 引き継ぎ一覧取得を行う。
         * @returns OK
         */
        $get: (option: { query: Methods0['get']['query'], config?: T | undefined }) =>
          fetch<Methods0['get']['resBody'], BasicHeaders, Methods0['get']['status']>(prefix, PATH0, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods0['get']['query'] } | undefined) =>
          `${prefix}${PATH0}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
      }
    },
    cloudfront: {
      pre_signed_url: {
        /**
         * 署名付きURL生成を行う。
         * @returns 搬送業者登録成功
         */
        post: (option: { body: Methods2['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods2['post']['resBody'], BasicHeaders, Methods2['post']['status']>(prefix, PATH1, POST, option).json(),
        /**
         * 署名付きURL生成を行う。
         * @returns 搬送業者登録成功
         */
        $post: (option: { body: Methods2['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods2['post']['resBody'], BasicHeaders, Methods2['post']['status']>(prefix, PATH1, POST, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH1}`
      }
    },
    excel: {
      funerals: {
        /**
         * 葬儀一覧ダウンロードを行う。MAX1,000件まで。
         * @returns 成功
         */
        get: (option?: { query?: Methods3['get']['query'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods3['get']['resBody'], BasicHeaders, Methods3['get']['status']>(prefix, PATH2, GET, option).blob(),
        /**
         * 葬儀一覧ダウンロードを行う。MAX1,000件まで。
         * @returns 成功
         */
        $get: (option?: { query?: Methods3['get']['query'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods3['get']['resBody'], BasicHeaders, Methods3['get']['status']>(prefix, PATH2, GET, option).blob().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods3['get']['query'] } | undefined) =>
          `${prefix}${PATH2}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
      },
      master: {
        areas: {
          /**
           * マスタダウンロード（エリア）
           * @returns 成功
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods4['get']['resBody'], BasicHeaders, Methods4['get']['status']>(prefix, PATH3, GET, option).blob(),
          /**
           * マスタダウンロード（エリア）
           * @returns 成功
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods4['get']['resBody'], BasicHeaders, Methods4['get']['status']>(prefix, PATH3, GET, option).blob().then(r => r.body),
          $path: () => `${prefix}${PATH3}`
        },
        carrier_conditions: {
          /**
           * マスタダウンロード（搬送業者割）
           * @returns 成功
           */
          get: (option?: { query?: Methods5['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods5['get']['resBody'], BasicHeaders, Methods5['get']['status']>(prefix, PATH4, GET, option).blob(),
          /**
           * マスタダウンロード（搬送業者割）
           * @returns 成功
           */
          $get: (option?: { query?: Methods5['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods5['get']['resBody'], BasicHeaders, Methods5['get']['status']>(prefix, PATH4, GET, option).blob().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods5['get']['query'] } | undefined) =>
            `${prefix}${PATH4}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
        },
        centers: {
          /**
           * マスタダウンロード（葬祭センター）
           * @returns 成功
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods6['get']['resBody'], BasicHeaders, Methods6['get']['status']>(prefix, PATH5, GET, option).blob(),
          /**
           * マスタダウンロード（葬祭センター）
           * @returns 成功
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods6['get']['resBody'], BasicHeaders, Methods6['get']['status']>(prefix, PATH5, GET, option).blob().then(r => r.body),
          $path: () => `${prefix}${PATH5}`
        },
        halls: {
          /**
           * マスタダウンロード（ホール）
           * @returns 成功
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods7['get']['resBody'], BasicHeaders, Methods7['get']['status']>(prefix, PATH6, GET, option).blob(),
          /**
           * マスタダウンロード（ホール）
           * @returns 成功
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods7['get']['resBody'], BasicHeaders, Methods7['get']['status']>(prefix, PATH6, GET, option).blob().then(r => r.body),
          $path: () => `${prefix}${PATH6}`
        },
        hospitals: {
          /**
           * マスタダウンロード（病院）
           * @returns 成功
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods8['get']['resBody'], BasicHeaders, Methods8['get']['status']>(prefix, PATH7, GET, option).blob(),
          /**
           * マスタダウンロード（病院）
           * @returns 成功
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods8['get']['resBody'], BasicHeaders, Methods8['get']['status']>(prefix, PATH7, GET, option).blob().then(r => r.body),
          $path: () => `${prefix}${PATH7}`
        },
        japan_agricultural_branches: {
          /**
           * マスタダウンロード（JA支店）
           * @returns 成功
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods9['get']['resBody'], BasicHeaders, Methods9['get']['status']>(prefix, PATH8, GET, option).blob(),
          /**
           * マスタダウンロード（JA支店）
           * @returns 成功
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods9['get']['resBody'], BasicHeaders, Methods9['get']['status']>(prefix, PATH8, GET, option).blob().then(r => r.body),
          $path: () => `${prefix}${PATH8}`
        },
        japan_agriculturals: {
          /**
           * マスタダウンロード（JA）
           * @returns 成功
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods10['get']['resBody'], BasicHeaders, Methods10['get']['status']>(prefix, PATH9, GET, option).blob(),
          /**
           * マスタダウンロード（JA）
           * @returns 成功
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods10['get']['resBody'], BasicHeaders, Methods10['get']['status']>(prefix, PATH9, GET, option).blob().then(r => r.body),
          $path: () => `${prefix}${PATH9}`
        },
        mortuaries: {
          /**
           * マスタダウンロード（安置先）
           * @returns 成功
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods11['get']['resBody'], BasicHeaders, Methods11['get']['status']>(prefix, PATH10, GET, option).blob(),
          /**
           * マスタダウンロード（安置先）
           * @returns 成功
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods11['get']['resBody'], BasicHeaders, Methods11['get']['status']>(prefix, PATH10, GET, option).blob().then(r => r.body),
          $path: () => `${prefix}${PATH10}`
        },
        partners: {
          /**
           * マスタダウンロード（契約社）
           * @returns 成功
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods12['get']['resBody'], BasicHeaders, Methods12['get']['status']>(prefix, PATH11, GET, option).blob(),
          /**
           * マスタダウンロード（契約社）
           * @returns 成功
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods12['get']['resBody'], BasicHeaders, Methods12['get']['status']>(prefix, PATH11, GET, option).blob().then(r => r.body),
          $path: () => `${prefix}${PATH11}`
        },
        phone_lines: {
          /**
           * マスタダウンロード（回線）
           * @returns 成功
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods13['get']['resBody'], BasicHeaders, Methods13['get']['status']>(prefix, PATH12, GET, option).blob(),
          /**
           * マスタダウンロード（回線）
           * @returns 成功
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods13['get']['resBody'], BasicHeaders, Methods13['get']['status']>(prefix, PATH12, GET, option).blob().then(r => r.body),
          $path: () => `${prefix}${PATH12}`
        },
        regions: {
          /**
           * マスタダウンロード（地区）
           * @returns 成功
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods14['get']['resBody'], BasicHeaders, Methods14['get']['status']>(prefix, PATH13, GET, option).blob(),
          /**
           * マスタダウンロード（地区）
           * @returns 成功
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods14['get']['resBody'], BasicHeaders, Methods14['get']['status']>(prefix, PATH13, GET, option).blob().then(r => r.body),
          $path: () => `${prefix}${PATH13}`
        },
        stores: {
          /**
           * マスタダウンロード（店舗）
           * @returns 成功
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods15['get']['resBody'], BasicHeaders, Methods15['get']['status']>(prefix, PATH14, GET, option).blob(),
          /**
           * マスタダウンロード（店舗）
           * @returns 成功
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods15['get']['resBody'], BasicHeaders, Methods15['get']['status']>(prefix, PATH14, GET, option).blob().then(r => r.body),
          $path: () => `${prefix}${PATH14}`
        }
      },
      summary: {
        /**
         * 店舗別集計表ダウンロードを行う。
         * @returns 成功
         */
        get: (option: { query: Methods16['get']['query'], config?: T | undefined }) =>
          fetch<Methods16['get']['resBody'], BasicHeaders, Methods16['get']['status']>(prefix, PATH15, GET, option).blob(),
        /**
         * 店舗別集計表ダウンロードを行う。
         * @returns 成功
         */
        $get: (option: { query: Methods16['get']['query'], config?: T | undefined }) =>
          fetch<Methods16['get']['resBody'], BasicHeaders, Methods16['get']['status']>(prefix, PATH15, GET, option).blob().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods16['get']['query'] } | undefined) =>
          `${prefix}${PATH15}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
      },
      tasks: {
        _task_id: (val2: number) => {
          const prefix2 = `${PATH16}/${val2}`

          return {
            /**
             * 注文書ダウンロードを行う
             * @returns 成功
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods18['get']['resBody'], BasicHeaders, Methods18['get']['status']>(prefix, prefix2, GET, option).blob(),
            /**
             * 注文書ダウンロードを行う
             * @returns 成功
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods18['get']['resBody'], BasicHeaders, Methods18['get']['status']>(prefix, prefix2, GET, option).blob().then(r => r.body),
            $path: () => `${prefix}${prefix2}`
          }
        },
        /**
         * 業務一覧ダウンロードを行う。MAX1,000件まで。
         * @returns 成功
         */
        get: (option?: { query?: Methods17['get']['query'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods17['get']['resBody'], BasicHeaders, Methods17['get']['status']>(prefix, PATH16, GET, option).blob(),
        /**
         * 業務一覧ダウンロードを行う。MAX1,000件まで。
         * @returns 成功
         */
        $get: (option?: { query?: Methods17['get']['query'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods17['get']['resBody'], BasicHeaders, Methods17['get']['status']>(prefix, PATH16, GET, option).blob().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods17['get']['query'] } | undefined) =>
          `${prefix}${PATH16}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
      }
    },
    funerals: {
      _funeral_id: (val1: number) => {
        const prefix1 = `${PATH17}/${val1}`

        return {
          /**
           * 葬儀取得する
           * @returns OK
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods20['get']['resBody'], BasicHeaders, Methods20['get']['status']>(prefix, prefix1, GET, option).json(),
          /**
           * 葬儀取得する
           * @returns OK
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods20['get']['resBody'], BasicHeaders, Methods20['get']['status']>(prefix, prefix1, GET, option).json().then(r => r.body),
          /**
           * 葬儀更新する
           * @param option.body - 葬儀更新時情報
           */
          put: (option: { body: Methods20['put']['reqBody'], config?: T | undefined }) =>
            fetch<void, BasicHeaders, Methods20['put']['status']>(prefix, prefix1, PUT, option).send(),
          /**
           * 葬儀更新する
           * @param option.body - 葬儀更新時情報
           */
          $put: (option: { body: Methods20['put']['reqBody'], config?: T | undefined }) =>
            fetch<void, BasicHeaders, Methods20['put']['status']>(prefix, prefix1, PUT, option).send().then(r => r.body),
          /**
           * 葬儀削除する
           */
          delete: (option?: { config?: T | undefined } | undefined) =>
            fetch<void, BasicHeaders, Methods20['delete']['status']>(prefix, prefix1, DELETE, option).send(),
          /**
           * 葬儀削除する
           */
          $delete: (option?: { config?: T | undefined } | undefined) =>
            fetch<void, BasicHeaders, Methods20['delete']['status']>(prefix, prefix1, DELETE, option).send().then(r => r.body),
          $path: () => `${prefix}${prefix1}`
        }
      },
      /**
       * 葬儀一覧取得を行う。
       * @returns OK
       */
      get: (option: { query: Methods19['get']['query'], config?: T | undefined }) =>
        fetch<Methods19['get']['resBody'], BasicHeaders, Methods19['get']['status']>(prefix, PATH17, GET, option).json(),
      /**
       * 葬儀一覧取得を行う。
       * @returns OK
       */
      $get: (option: { query: Methods19['get']['query'], config?: T | undefined }) =>
        fetch<Methods19['get']['resBody'], BasicHeaders, Methods19['get']['status']>(prefix, PATH17, GET, option).json().then(r => r.body),
      /**
       * 葬儀追加を行う
       * @param option.body - 葬儀追加時情報
       * @returns Created
       */
      post: (option: { body: Methods19['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods19['post']['resBody'], BasicHeaders, Methods19['post']['status']>(prefix, PATH17, POST, option).json(),
      /**
       * 葬儀追加を行う
       * @param option.body - 葬儀追加時情報
       * @returns Created
       */
      $post: (option: { body: Methods19['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods19['post']['resBody'], BasicHeaders, Methods19['post']['status']>(prefix, PATH17, POST, option).json().then(r => r.body),
      $path: (option?: { method?: 'get' | undefined; query: Methods19['get']['query'] } | undefined) =>
        `${prefix}${PATH17}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
    },
    login: {
      /**
       * ログインの試行を行う
       * @returns ログイン成功
       */
      post: (option: { body: Methods21['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods21['post']['resBody'], BasicHeaders, Methods21['post']['status']>(prefix, PATH18, POST, option).json(),
      /**
       * ログインの試行を行う
       * @returns ログイン成功
       */
      $post: (option: { body: Methods21['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods21['post']['resBody'], BasicHeaders, Methods21['post']['status']>(prefix, PATH18, POST, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH18}`
    },
    master: {
      areas: {
        _area_id: (val2: number) => {
          const prefix2 = `${PATH19}/${val2}`

          return {
            subareas: {
              _subarea_id: (val4: number) => {
                const prefix4 = `${prefix2}${PATH20}/${val4}`

                return {
                  /**
                   * サブエリアの取得を行う。
                   * @returns サブエリア取得成功
                   */
                  get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods25['get']['resBody'], BasicHeaders, Methods25['get']['status']>(prefix, prefix4, GET, option).json(),
                  /**
                   * サブエリアの取得を行う。
                   * @returns サブエリア取得成功
                   */
                  $get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods25['get']['resBody'], BasicHeaders, Methods25['get']['status']>(prefix, prefix4, GET, option).json().then(r => r.body),
                  /**
                   * サブエリアの更新を行う。
                   */
                  put: (option: { body: Methods25['put']['reqBody'], config?: T | undefined }) =>
                    fetch<void, BasicHeaders, Methods25['put']['status']>(prefix, prefix4, PUT, option).send(),
                  /**
                   * サブエリアの更新を行う。
                   */
                  $put: (option: { body: Methods25['put']['reqBody'], config?: T | undefined }) =>
                    fetch<void, BasicHeaders, Methods25['put']['status']>(prefix, prefix4, PUT, option).send().then(r => r.body),
                  /**
                   * サブエリアの削除を行う。
                   */
                  delete: (option?: { config?: T | undefined } | undefined) =>
                    fetch<void, BasicHeaders, Methods25['delete']['status']>(prefix, prefix4, DELETE, option).send(),
                  /**
                   * サブエリアの削除を行う。
                   */
                  $delete: (option?: { config?: T | undefined } | undefined) =>
                    fetch<void, BasicHeaders, Methods25['delete']['status']>(prefix, prefix4, DELETE, option).send().then(r => r.body),
                  $path: () => `${prefix}${prefix4}`
                }
              },
              /**
               * サブエリア一覧取得を行う。
               * @returns OK
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods24['get']['resBody'], BasicHeaders, Methods24['get']['status']>(prefix, `${prefix2}${PATH20}`, GET, option).json(),
              /**
               * サブエリア一覧取得を行う。
               * @returns OK
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods24['get']['resBody'], BasicHeaders, Methods24['get']['status']>(prefix, `${prefix2}${PATH20}`, GET, option).json().then(r => r.body),
              /**
               * サブエリアの追加を行う。
               * @returns サブエリア登録成功
               */
              post: (option: { body: Methods24['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods24['post']['resBody'], BasicHeaders, Methods24['post']['status']>(prefix, `${prefix2}${PATH20}`, POST, option).json(),
              /**
               * サブエリアの追加を行う。
               * @returns サブエリア登録成功
               */
              $post: (option: { body: Methods24['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods24['post']['resBody'], BasicHeaders, Methods24['post']['status']>(prefix, `${prefix2}${PATH20}`, POST, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH20}`
            },
            /**
             * エリアの取得を行う。
             * @returns ホール取得成功
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods23['get']['resBody'], BasicHeaders, Methods23['get']['status']>(prefix, prefix2, GET, option).json(),
            /**
             * エリアの取得を行う。
             * @returns ホール取得成功
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods23['get']['resBody'], BasicHeaders, Methods23['get']['status']>(prefix, prefix2, GET, option).json().then(r => r.body),
            /**
             * ホールの更新を行う。
             */
            put: (option: { body: Methods23['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods23['put']['status']>(prefix, prefix2, PUT, option).send(),
            /**
             * ホールの更新を行う。
             */
            $put: (option: { body: Methods23['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods23['put']['status']>(prefix, prefix2, PUT, option).send().then(r => r.body),
            /**
             * エリアの削除を行う。
             */
            delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<void, BasicHeaders, Methods23['delete']['status']>(prefix, prefix2, DELETE, option).send(),
            /**
             * エリアの削除を行う。
             */
            $delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<void, BasicHeaders, Methods23['delete']['status']>(prefix, prefix2, DELETE, option).send().then(r => r.body),
            $path: () => `${prefix}${prefix2}`
          }
        },
        /**
         * エリア一覧取得を行う。このAPIはページングなし全件取得。
         * @returns OK
         */
        get: (option?: { query?: Methods22['get']['query'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods22['get']['resBody'], BasicHeaders, Methods22['get']['status']>(prefix, PATH19, GET, option).json(),
        /**
         * エリア一覧取得を行う。このAPIはページングなし全件取得。
         * @returns OK
         */
        $get: (option?: { query?: Methods22['get']['query'] | undefined, config?: T | undefined } | undefined) =>
          fetch<Methods22['get']['resBody'], BasicHeaders, Methods22['get']['status']>(prefix, PATH19, GET, option).json().then(r => r.body),
        /**
         * エリアの追加を行う。
         * @returns エリア登録成功
         */
        post: (option: { body: Methods22['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods22['post']['resBody'], BasicHeaders, Methods22['post']['status']>(prefix, PATH19, POST, option).json(),
        /**
         * エリアの追加を行う。
         * @returns エリア登録成功
         */
        $post: (option: { body: Methods22['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods22['post']['resBody'], BasicHeaders, Methods22['post']['status']>(prefix, PATH19, POST, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods22['get']['query'] } | undefined) =>
          `${prefix}${PATH19}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
      },
      centers: {
        _center_id: (val2: number) => {
          const prefix2 = `${PATH21}/${val2}`

          return {
            hospitals: {
              _hospital_id: (val4: number) => {
                const prefix4 = `${prefix2}${PATH22}/${val4}`

                return {
                  /**
                   * 病院の取得を行う。
                   * @returns 病院取得成功
                   */
                  get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods29['get']['resBody'], BasicHeaders, Methods29['get']['status']>(prefix, prefix4, GET, option).json(),
                  /**
                   * 病院の取得を行う。
                   * @returns 病院取得成功
                   */
                  $get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods29['get']['resBody'], BasicHeaders, Methods29['get']['status']>(prefix, prefix4, GET, option).json().then(r => r.body),
                  /**
                   * 病院の更新を行う。
                   */
                  put: (option: { body: Methods29['put']['reqBody'], config?: T | undefined }) =>
                    fetch<void, BasicHeaders, Methods29['put']['status']>(prefix, prefix4, PUT, option).send(),
                  /**
                   * 病院の更新を行う。
                   */
                  $put: (option: { body: Methods29['put']['reqBody'], config?: T | undefined }) =>
                    fetch<void, BasicHeaders, Methods29['put']['status']>(prefix, prefix4, PUT, option).send().then(r => r.body),
                  /**
                   * 病院の削除を行う。
                   */
                  delete: (option?: { config?: T | undefined } | undefined) =>
                    fetch<void, BasicHeaders, Methods29['delete']['status']>(prefix, prefix4, DELETE, option).send(),
                  /**
                   * 病院の削除を行う。
                   */
                  $delete: (option?: { config?: T | undefined } | undefined) =>
                    fetch<void, BasicHeaders, Methods29['delete']['status']>(prefix, prefix4, DELETE, option).send().then(r => r.body),
                  $path: () => `${prefix}${prefix4}`
                }
              },
              /**
               * 病院一覧取得を行う。
               * @returns OK
               */
              get: (option: { query: Methods28['get']['query'], config?: T | undefined }) =>
                fetch<Methods28['get']['resBody'], BasicHeaders, Methods28['get']['status']>(prefix, `${prefix2}${PATH22}`, GET, option).json(),
              /**
               * 病院一覧取得を行う。
               * @returns OK
               */
              $get: (option: { query: Methods28['get']['query'], config?: T | undefined }) =>
                fetch<Methods28['get']['resBody'], BasicHeaders, Methods28['get']['status']>(prefix, `${prefix2}${PATH22}`, GET, option).json().then(r => r.body),
              /**
               * 病院の追加を行う。
               * @returns 病院登録成功
               */
              post: (option: { body: Methods28['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods28['post']['resBody'], BasicHeaders, Methods28['post']['status']>(prefix, `${prefix2}${PATH22}`, POST, option).json(),
              /**
               * 病院の追加を行う。
               * @returns 病院登録成功
               */
              $post: (option: { body: Methods28['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods28['post']['resBody'], BasicHeaders, Methods28['post']['status']>(prefix, `${prefix2}${PATH22}`, POST, option).json().then(r => r.body),
              $path: (option?: { method?: 'get' | undefined; query: Methods28['get']['query'] } | undefined) =>
                `${prefix}${prefix2}${PATH22}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
            },
            mortuaries: {
              _mortuary_id: (val4: string) => {
                const prefix4 = `${prefix2}${PATH23}/${val4}`

                return {
                  /**
                   * 安置先取得する
                   * @returns OK
                   */
                  get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods31['get']['resBody'], BasicHeaders, Methods31['get']['status']>(prefix, prefix4, GET, option).json(),
                  /**
                   * 安置先取得する
                   * @returns OK
                   */
                  $get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods31['get']['resBody'], BasicHeaders, Methods31['get']['status']>(prefix, prefix4, GET, option).json().then(r => r.body),
                  /**
                   * 安置先更新する
                   * @param option.body - 安置先更新時情報
                   */
                  put: (option: { body: Methods31['put']['reqBody'], config?: T | undefined }) =>
                    fetch<void, BasicHeaders, Methods31['put']['status']>(prefix, prefix4, PUT, option).send(),
                  /**
                   * 安置先更新する
                   * @param option.body - 安置先更新時情報
                   */
                  $put: (option: { body: Methods31['put']['reqBody'], config?: T | undefined }) =>
                    fetch<void, BasicHeaders, Methods31['put']['status']>(prefix, prefix4, PUT, option).send().then(r => r.body),
                  /**
                   * 安置先削除する
                   */
                  delete: (option?: { config?: T | undefined } | undefined) =>
                    fetch<void, BasicHeaders, Methods31['delete']['status']>(prefix, prefix4, DELETE, option).send(),
                  /**
                   * 安置先削除する
                   */
                  $delete: (option?: { config?: T | undefined } | undefined) =>
                    fetch<void, BasicHeaders, Methods31['delete']['status']>(prefix, prefix4, DELETE, option).send().then(r => r.body),
                  $path: () => `${prefix}${prefix4}`
                }
              },
              /**
               * 安置先一覧取得を行う。
               * @returns OK
               */
              get: (option: { query: Methods30['get']['query'], config?: T | undefined }) =>
                fetch<Methods30['get']['resBody'], BasicHeaders, Methods30['get']['status']>(prefix, `${prefix2}${PATH23}`, GET, option).json(),
              /**
               * 安置先一覧取得を行う。
               * @returns OK
               */
              $get: (option: { query: Methods30['get']['query'], config?: T | undefined }) =>
                fetch<Methods30['get']['resBody'], BasicHeaders, Methods30['get']['status']>(prefix, `${prefix2}${PATH23}`, GET, option).json().then(r => r.body),
              /**
               * 安置先追加する
               * @param option.body - 安置先追加時情報
               * @returns Created
               */
              post: (option: { body: Methods30['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods30['post']['resBody'], BasicHeaders, Methods30['post']['status']>(prefix, `${prefix2}${PATH23}`, POST, option).json(),
              /**
               * 安置先追加する
               * @param option.body - 安置先追加時情報
               * @returns Created
               */
              $post: (option: { body: Methods30['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods30['post']['resBody'], BasicHeaders, Methods30['post']['status']>(prefix, `${prefix2}${PATH23}`, POST, option).json().then(r => r.body),
              $path: (option?: { method?: 'get' | undefined; query: Methods30['get']['query'] } | undefined) =>
                `${prefix}${prefix2}${PATH23}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
            },
            stores: {
              /**
               * 葬祭センター配下店舗一覧（業務画面用）取得を行う。
               * @returns OK
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods32['get']['resBody'], BasicHeaders, Methods32['get']['status']>(prefix, `${prefix2}${PATH24}`, GET, option).json(),
              /**
               * 葬祭センター配下店舗一覧（業務画面用）取得を行う。
               * @returns OK
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods32['get']['resBody'], BasicHeaders, Methods32['get']['status']>(prefix, `${prefix2}${PATH24}`, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH24}`
            },
            /**
             * 葬祭センターの取得を行う。
             * @returns 葬祭センター取得成功
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods27['get']['resBody'], BasicHeaders, Methods27['get']['status']>(prefix, prefix2, GET, option).json(),
            /**
             * 葬祭センターの取得を行う。
             * @returns 葬祭センター取得成功
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods27['get']['resBody'], BasicHeaders, Methods27['get']['status']>(prefix, prefix2, GET, option).json().then(r => r.body),
            /**
             * 葬祭センターの更新を行う。
             */
            put: (option: { body: Methods27['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods27['put']['status']>(prefix, prefix2, PUT, option).send(),
            /**
             * 葬祭センターの更新を行う。
             */
            $put: (option: { body: Methods27['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods27['put']['status']>(prefix, prefix2, PUT, option).send().then(r => r.body),
            /**
             * 葬祭センターの削除を行う。
             */
            delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<void, BasicHeaders, Methods27['delete']['status']>(prefix, prefix2, DELETE, option).send(),
            /**
             * 葬祭センターの削除を行う。
             */
            $delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<void, BasicHeaders, Methods27['delete']['status']>(prefix, prefix2, DELETE, option).send().then(r => r.body),
            $path: () => `${prefix}${prefix2}`
          }
        },
        /**
         * 葬祭センター一覧取得を行う。
         * @returns OK
         */
        get: (option: { query: Methods26['get']['query'], config?: T | undefined }) =>
          fetch<Methods26['get']['resBody'], BasicHeaders, Methods26['get']['status']>(prefix, PATH21, GET, option).json(),
        /**
         * 葬祭センター一覧取得を行う。
         * @returns OK
         */
        $get: (option: { query: Methods26['get']['query'], config?: T | undefined }) =>
          fetch<Methods26['get']['resBody'], BasicHeaders, Methods26['get']['status']>(prefix, PATH21, GET, option).json().then(r => r.body),
        /**
         * 葬祭センターの追加を行う。
         * @returns 葬祭センター登録成功
         */
        post: (option: { body: Methods26['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods26['post']['resBody'], BasicHeaders, Methods26['post']['status']>(prefix, PATH21, POST, option).json(),
        /**
         * 葬祭センターの追加を行う。
         * @returns 葬祭センター登録成功
         */
        $post: (option: { body: Methods26['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods26['post']['resBody'], BasicHeaders, Methods26['post']['status']>(prefix, PATH21, POST, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods26['get']['query'] } | undefined) =>
          `${prefix}${PATH21}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
      },
      halls: {
        _hall_id: (val2: number) => {
          const prefix2 = `${PATH25}/${val2}`

          return {
            /**
             * ホールの取得を行う。
             * @returns ホール取得成功
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods34['get']['resBody'], BasicHeaders, Methods34['get']['status']>(prefix, prefix2, GET, option).json(),
            /**
             * ホールの取得を行う。
             * @returns ホール取得成功
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods34['get']['resBody'], BasicHeaders, Methods34['get']['status']>(prefix, prefix2, GET, option).json().then(r => r.body),
            /**
             * ホールの更新を行う。
             */
            put: (option: { body: Methods34['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods34['put']['status']>(prefix, prefix2, PUT, option).send(),
            /**
             * ホールの更新を行う。
             */
            $put: (option: { body: Methods34['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods34['put']['status']>(prefix, prefix2, PUT, option).send().then(r => r.body),
            /**
             * ホールの削除を行う。
             */
            delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<void, BasicHeaders, Methods34['delete']['status']>(prefix, prefix2, DELETE, option).send(),
            /**
             * ホールの削除を行う。
             */
            $delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<void, BasicHeaders, Methods34['delete']['status']>(prefix, prefix2, DELETE, option).send().then(r => r.body),
            $path: () => `${prefix}${prefix2}`
          }
        },
        /**
         * ホール一覧取得を行う。
         * @returns OK
         */
        get: (option: { query: Methods33['get']['query'], config?: T | undefined }) =>
          fetch<Methods33['get']['resBody'], BasicHeaders, Methods33['get']['status']>(prefix, PATH25, GET, option).json(),
        /**
         * ホール一覧取得を行う。
         * @returns OK
         */
        $get: (option: { query: Methods33['get']['query'], config?: T | undefined }) =>
          fetch<Methods33['get']['resBody'], BasicHeaders, Methods33['get']['status']>(prefix, PATH25, GET, option).json().then(r => r.body),
        /**
         * ホールの追加を行う。
         * @returns ホール登録成功
         */
        post: (option: { body: Methods33['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods33['post']['resBody'], BasicHeaders, Methods33['post']['status']>(prefix, PATH25, POST, option).json(),
        /**
         * ホールの追加を行う。
         * @returns ホール登録成功
         */
        $post: (option: { body: Methods33['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods33['post']['resBody'], BasicHeaders, Methods33['post']['status']>(prefix, PATH25, POST, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods33['get']['query'] } | undefined) =>
          `${prefix}${PATH25}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
      },
      japan_agricultural_branches: {
        _japan_agricultural_branch_id: (val2: number) => {
          const prefix2 = `${PATH26}/${val2}`

          return {
            /**
             * JA支店取得する
             * @returns OK
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods36['get']['resBody'], BasicHeaders, Methods36['get']['status']>(prefix, prefix2, GET, option).json(),
            /**
             * JA支店取得する
             * @returns OK
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods36['get']['resBody'], BasicHeaders, Methods36['get']['status']>(prefix, prefix2, GET, option).json().then(r => r.body),
            /**
             * JA支店更新する
             * @param option.body - JA支店更新時情報
             */
            put: (option: { body: Methods36['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods36['put']['status']>(prefix, prefix2, PUT, option).send(),
            /**
             * JA支店更新する
             * @param option.body - JA支店更新時情報
             */
            $put: (option: { body: Methods36['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods36['put']['status']>(prefix, prefix2, PUT, option).send().then(r => r.body),
            /**
             * JA支店削除する
             */
            delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<void, BasicHeaders, Methods36['delete']['status']>(prefix, prefix2, DELETE, option).send(),
            /**
             * JA支店削除する
             */
            $delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<void, BasicHeaders, Methods36['delete']['status']>(prefix, prefix2, DELETE, option).send().then(r => r.body),
            $path: () => `${prefix}${prefix2}`
          }
        },
        /**
         * JA支店一覧取得を行う。
         * @returns OK
         */
        get: (option: { query: Methods35['get']['query'], config?: T | undefined }) =>
          fetch<Methods35['get']['resBody'], BasicHeaders, Methods35['get']['status']>(prefix, PATH26, GET, option).json(),
        /**
         * JA支店一覧取得を行う。
         * @returns OK
         */
        $get: (option: { query: Methods35['get']['query'], config?: T | undefined }) =>
          fetch<Methods35['get']['resBody'], BasicHeaders, Methods35['get']['status']>(prefix, PATH26, GET, option).json().then(r => r.body),
        /**
         * JA支店追加する
         * @param option.body - JA支店追加時情報
         * @returns Created
         */
        post: (option: { body: Methods35['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods35['post']['resBody'], BasicHeaders, Methods35['post']['status']>(prefix, PATH26, POST, option).json(),
        /**
         * JA支店追加する
         * @param option.body - JA支店追加時情報
         * @returns Created
         */
        $post: (option: { body: Methods35['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods35['post']['resBody'], BasicHeaders, Methods35['post']['status']>(prefix, PATH26, POST, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods35['get']['query'] } | undefined) =>
          `${prefix}${PATH26}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
      },
      japan_agriculturals: {
        _japan_agricultural_id: (val2: number) => {
          const prefix2 = `${PATH27}/${val2}`

          return {
            /**
             * JA取得する
             * @returns OK
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods38['get']['resBody'], BasicHeaders, Methods38['get']['status']>(prefix, prefix2, GET, option).json(),
            /**
             * JA取得する
             * @returns OK
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods38['get']['resBody'], BasicHeaders, Methods38['get']['status']>(prefix, prefix2, GET, option).json().then(r => r.body),
            /**
             * JA更新する
             * @param option.body - JA更新時情報
             */
            put: (option: { body: Methods38['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods38['put']['status']>(prefix, prefix2, PUT, option).send(),
            /**
             * JA更新する
             * @param option.body - JA更新時情報
             */
            $put: (option: { body: Methods38['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods38['put']['status']>(prefix, prefix2, PUT, option).send().then(r => r.body),
            /**
             * JA削除する
             */
            delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<void, BasicHeaders, Methods38['delete']['status']>(prefix, prefix2, DELETE, option).send(),
            /**
             * JA削除する
             */
            $delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<void, BasicHeaders, Methods38['delete']['status']>(prefix, prefix2, DELETE, option).send().then(r => r.body),
            $path: () => `${prefix}${prefix2}`
          }
        },
        /**
         * JA一覧取得を行う。
         * @returns OK
         */
        get: (option: { query: Methods37['get']['query'], config?: T | undefined }) =>
          fetch<Methods37['get']['resBody'], BasicHeaders, Methods37['get']['status']>(prefix, PATH27, GET, option).json(),
        /**
         * JA一覧取得を行う。
         * @returns OK
         */
        $get: (option: { query: Methods37['get']['query'], config?: T | undefined }) =>
          fetch<Methods37['get']['resBody'], BasicHeaders, Methods37['get']['status']>(prefix, PATH27, GET, option).json().then(r => r.body),
        /**
         * JA追加する
         * @param option.body - JA追加時情報
         * @returns Created
         */
        post: (option: { body: Methods37['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods37['post']['resBody'], BasicHeaders, Methods37['post']['status']>(prefix, PATH27, POST, option).json(),
        /**
         * JA追加する
         * @param option.body - JA追加時情報
         * @returns Created
         */
        $post: (option: { body: Methods37['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods37['post']['resBody'], BasicHeaders, Methods37['post']['status']>(prefix, PATH27, POST, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods37['get']['query'] } | undefined) =>
          `${prefix}${PATH27}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
      },
      partners: {
        _partner_id: (val2: number) => {
          const prefix2 = `${PATH28}/${val2}`

          return {
            phone_lines: {
              /**
               * 回線一覧（業務画面用）取得を行う。
               * @returns OK
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods41['get']['resBody'], BasicHeaders, Methods41['get']['status']>(prefix, `${prefix2}${PATH29}`, GET, option).json(),
              /**
               * 回線一覧（業務画面用）取得を行う。
               * @returns OK
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods41['get']['resBody'], BasicHeaders, Methods41['get']['status']>(prefix, `${prefix2}${PATH29}`, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH29}`
            },
            /**
             * 契約社の取得を行う。
             * @returns 契約社取得成功
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods40['get']['resBody'], BasicHeaders, Methods40['get']['status']>(prefix, prefix2, GET, option).json(),
            /**
             * 契約社の取得を行う。
             * @returns 契約社取得成功
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods40['get']['resBody'], BasicHeaders, Methods40['get']['status']>(prefix, prefix2, GET, option).json().then(r => r.body),
            /**
             * 契約社の更新を行う。
             */
            put: (option: { body: Methods40['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods40['put']['status']>(prefix, prefix2, PUT, option).send(),
            /**
             * 契約社の更新を行う。
             */
            $put: (option: { body: Methods40['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods40['put']['status']>(prefix, prefix2, PUT, option).send().then(r => r.body),
            /**
             * 契約社の削除を行う。
             */
            delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<void, BasicHeaders, Methods40['delete']['status']>(prefix, prefix2, DELETE, option).send(),
            /**
             * 契約社の削除を行う。
             */
            $delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<void, BasicHeaders, Methods40['delete']['status']>(prefix, prefix2, DELETE, option).send().then(r => r.body),
            $path: () => `${prefix}${prefix2}`
          }
        },
        /**
         * 契約社一覧取得を行う。
         * @returns OK
         */
        get: (option: { query: Methods39['get']['query'], config?: T | undefined }) =>
          fetch<Methods39['get']['resBody'], BasicHeaders, Methods39['get']['status']>(prefix, PATH28, GET, option).json(),
        /**
         * 契約社一覧取得を行う。
         * @returns OK
         */
        $get: (option: { query: Methods39['get']['query'], config?: T | undefined }) =>
          fetch<Methods39['get']['resBody'], BasicHeaders, Methods39['get']['status']>(prefix, PATH28, GET, option).json().then(r => r.body),
        /**
         * 契約社の追加を行う。
         * @returns 契約社登録成功
         */
        post: (option: { body: Methods39['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods39['post']['resBody'], BasicHeaders, Methods39['post']['status']>(prefix, PATH28, POST, option).json(),
        /**
         * 契約社の追加を行う。
         * @returns 契約社登録成功
         */
        $post: (option: { body: Methods39['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods39['post']['resBody'], BasicHeaders, Methods39['post']['status']>(prefix, PATH28, POST, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods39['get']['query'] } | undefined) =>
          `${prefix}${PATH28}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
      },
      phone_lines: {
        _phone_line_id: (val2: number) => {
          const prefix2 = `${PATH30}/${val2}`

          return {
            areas: {
              /**
               * エリア一覧（業務画面用）取得を行う。希望葬儀式場で利用する
               * @returns OK
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods44['get']['resBody'], BasicHeaders, Methods44['get']['status']>(prefix, `${prefix2}${PATH31}`, GET, option).json(),
              /**
               * エリア一覧（業務画面用）取得を行う。希望葬儀式場で利用する
               * @returns OK
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods44['get']['resBody'], BasicHeaders, Methods44['get']['status']>(prefix, `${prefix2}${PATH31}`, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH31}`
            },
            attentions: {
              _attention_id: (val4: number) => {
                const prefix4 = `${prefix2}${PATH32}/${val4}`

                return {
                  /**
                   * 留意点取得を行う
                   * @returns 留意点取得成功
                   */
                  get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods46['get']['resBody'], BasicHeaders, Methods46['get']['status']>(prefix, prefix4, GET, option).json(),
                  /**
                   * 留意点取得を行う
                   * @returns 留意点取得成功
                   */
                  $get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods46['get']['resBody'], BasicHeaders, Methods46['get']['status']>(prefix, prefix4, GET, option).json().then(r => r.body),
                  /**
                   * 留意点を更新する
                   */
                  put: (option: { body: Methods46['put']['reqBody'], config?: T | undefined }) =>
                    fetch<void, BasicHeaders, Methods46['put']['status']>(prefix, prefix4, PUT, option).send(),
                  /**
                   * 留意点を更新する
                   */
                  $put: (option: { body: Methods46['put']['reqBody'], config?: T | undefined }) =>
                    fetch<void, BasicHeaders, Methods46['put']['status']>(prefix, prefix4, PUT, option).send().then(r => r.body),
                  /**
                   * 留意点の削除を行う。
                   */
                  delete: (option?: { config?: T | undefined } | undefined) =>
                    fetch<void, BasicHeaders, Methods46['delete']['status']>(prefix, prefix4, DELETE, option).send(),
                  /**
                   * 留意点の削除を行う。
                   */
                  $delete: (option?: { config?: T | undefined } | undefined) =>
                    fetch<void, BasicHeaders, Methods46['delete']['status']>(prefix, prefix4, DELETE, option).send().then(r => r.body),
                  $path: () => `${prefix}${prefix4}`
                }
              },
              /**
               * 留意点一覧取得を行う
               * @returns 留意点一覧取得成功
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods45['get']['resBody'], BasicHeaders, Methods45['get']['status']>(prefix, `${prefix2}${PATH32}`, GET, option).json(),
              /**
               * 留意点一覧取得を行う
               * @returns 留意点一覧取得成功
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods45['get']['resBody'], BasicHeaders, Methods45['get']['status']>(prefix, `${prefix2}${PATH32}`, GET, option).json().then(r => r.body),
              /**
               * 留意点の追加を行う
               * @returns 留意点追加成功
               */
              post: (option: { body: Methods45['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods45['post']['resBody'], BasicHeaders, Methods45['post']['status']>(prefix, `${prefix2}${PATH32}`, POST, option).json(),
              /**
               * 留意点の追加を行う
               * @returns 留意点追加成功
               */
              $post: (option: { body: Methods45['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods45['post']['resBody'], BasicHeaders, Methods45['post']['status']>(prefix, `${prefix2}${PATH32}`, POST, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH32}`
            },
            call_system_memos: {
              _call_system_memo_id: (val4: number) => {
                const prefix4 = `${prefix2}${PATH33}/${val4}`

                return {
                  /**
                   * コールシステムメモを取得する
                   * @returns コールシステムメモ取得成功
                   */
                  get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods48['get']['resBody'], BasicHeaders, Methods48['get']['status']>(prefix, prefix4, GET, option).json(),
                  /**
                   * コールシステムメモを取得する
                   * @returns コールシステムメモ取得成功
                   */
                  $get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods48['get']['resBody'], BasicHeaders, Methods48['get']['status']>(prefix, prefix4, GET, option).json().then(r => r.body),
                  /**
                   * コールシステムメモを更新する
                   */
                  put: (option: { body: Methods48['put']['reqBody'], config?: T | undefined }) =>
                    fetch<void, BasicHeaders, Methods48['put']['status']>(prefix, prefix4, PUT, option).send(),
                  /**
                   * コールシステムメモを更新する
                   */
                  $put: (option: { body: Methods48['put']['reqBody'], config?: T | undefined }) =>
                    fetch<void, BasicHeaders, Methods48['put']['status']>(prefix, prefix4, PUT, option).send().then(r => r.body),
                  $path: () => `${prefix}${prefix4}`
                }
              },
              /**
               * コールシステムメモ一覧取得を行う
               * @returns コールシステムメモ取得成功
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods47['get']['resBody'], BasicHeaders, Methods47['get']['status']>(prefix, `${prefix2}${PATH33}`, GET, option).json(),
              /**
               * コールシステムメモ一覧取得を行う
               * @returns コールシステムメモ取得成功
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods47['get']['resBody'], BasicHeaders, Methods47['get']['status']>(prefix, `${prefix2}${PATH33}`, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH33}`
            },
            carrier_conditions: {
              _carrier_condition_id: (val4: number) => {
                const prefix4 = `${prefix2}${PATH34}/${val4}`

                return {
                  update_display_order: {
                    /**
                     * 搬送業者割表示順更新する
                     * @param option.body - 新しい搬送業者割表示順情報
                     */
                    put: (option: { body: Methods51['put']['reqBody'], config?: T | undefined }) =>
                      fetch<void, BasicHeaders, Methods51['put']['status']>(prefix, `${prefix4}${PATH35}`, PUT, option).send(),
                    /**
                     * 搬送業者割表示順更新する
                     * @param option.body - 新しい搬送業者割表示順情報
                     */
                    $put: (option: { body: Methods51['put']['reqBody'], config?: T | undefined }) =>
                      fetch<void, BasicHeaders, Methods51['put']['status']>(prefix, `${prefix4}${PATH35}`, PUT, option).send().then(r => r.body),
                    $path: () => `${prefix}${prefix4}${PATH35}`
                  },
                  /**
                   * 搬送業者割取得する
                   * @returns OK
                   */
                  get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods50['get']['resBody'], BasicHeaders, Methods50['get']['status']>(prefix, prefix4, GET, option).json(),
                  /**
                   * 搬送業者割取得する
                   * @returns OK
                   */
                  $get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods50['get']['resBody'], BasicHeaders, Methods50['get']['status']>(prefix, prefix4, GET, option).json().then(r => r.body),
                  /**
                   * 搬送業者割更新する
                   * @param option.body - 搬送業者割更新時情報
                   */
                  put: (option: { body: Methods50['put']['reqBody'], config?: T | undefined }) =>
                    fetch<void, BasicHeaders, Methods50['put']['status']>(prefix, prefix4, PUT, option).send(),
                  /**
                   * 搬送業者割更新する
                   * @param option.body - 搬送業者割更新時情報
                   */
                  $put: (option: { body: Methods50['put']['reqBody'], config?: T | undefined }) =>
                    fetch<void, BasicHeaders, Methods50['put']['status']>(prefix, prefix4, PUT, option).send().then(r => r.body),
                  /**
                   * 搬送業者割削除を行う。
                   */
                  delete: (option?: { config?: T | undefined } | undefined) =>
                    fetch<void, BasicHeaders, Methods50['delete']['status']>(prefix, prefix4, DELETE, option).send(),
                  /**
                   * 搬送業者割削除を行う。
                   */
                  $delete: (option?: { config?: T | undefined } | undefined) =>
                    fetch<void, BasicHeaders, Methods50['delete']['status']>(prefix, prefix4, DELETE, option).send().then(r => r.body),
                  $path: () => `${prefix}${prefix4}`
                }
              },
              /**
               * 搬送業者割一覧を行う。
               * @returns 搬送業者割一覧成功
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods49['get']['resBody'], BasicHeaders, Methods49['get']['status']>(prefix, `${prefix2}${PATH34}`, GET, option).json(),
              /**
               * 搬送業者割一覧を行う。
               * @returns 搬送業者割一覧成功
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods49['get']['resBody'], BasicHeaders, Methods49['get']['status']>(prefix, `${prefix2}${PATH34}`, GET, option).json().then(r => r.body),
              /**
               * 搬送業者割追加を行う。
               * @returns 搬送業者割追加成功
               */
              post: (option: { body: Methods49['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods49['post']['resBody'], BasicHeaders, Methods49['post']['status']>(prefix, `${prefix2}${PATH34}`, POST, option).json(),
              /**
               * 搬送業者割追加を行う。
               * @returns 搬送業者割追加成功
               */
              $post: (option: { body: Methods49['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods49['post']['resBody'], BasicHeaders, Methods49['post']['status']>(prefix, `${prefix2}${PATH34}`, POST, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH34}`
            },
            carriers: {
              _carrier_id: (val4: number) => {
                const prefix4 = `${prefix2}${PATH36}/${val4}`

                return {
                  /**
                   * 搬送業者の取得を行う。
                   * @returns 搬送業者取得成功
                   */
                  get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods53['get']['resBody'], BasicHeaders, Methods53['get']['status']>(prefix, prefix4, GET, option).json(),
                  /**
                   * 搬送業者の取得を行う。
                   * @returns 搬送業者取得成功
                   */
                  $get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods53['get']['resBody'], BasicHeaders, Methods53['get']['status']>(prefix, prefix4, GET, option).json().then(r => r.body),
                  /**
                   * 搬送業者の更新を行う。
                   */
                  put: (option: { body: Methods53['put']['reqBody'], config?: T | undefined }) =>
                    fetch<void, BasicHeaders, Methods53['put']['status']>(prefix, prefix4, PUT, option).send(),
                  /**
                   * 搬送業者の更新を行う。
                   */
                  $put: (option: { body: Methods53['put']['reqBody'], config?: T | undefined }) =>
                    fetch<void, BasicHeaders, Methods53['put']['status']>(prefix, prefix4, PUT, option).send().then(r => r.body),
                  /**
                   * 搬送業者の削除を行う。
                   */
                  delete: (option?: { config?: T | undefined } | undefined) =>
                    fetch<void, BasicHeaders, Methods53['delete']['status']>(prefix, prefix4, DELETE, option).send(),
                  /**
                   * 搬送業者の削除を行う。
                   */
                  $delete: (option?: { config?: T | undefined } | undefined) =>
                    fetch<void, BasicHeaders, Methods53['delete']['status']>(prefix, prefix4, DELETE, option).send().then(r => r.body),
                  $path: () => `${prefix}${prefix4}`
                }
              },
              /**
               * 搬送業者一覧取得を行う。
               * @returns OK
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods52['get']['resBody'], BasicHeaders, Methods52['get']['status']>(prefix, `${prefix2}${PATH36}`, GET, option).json(),
              /**
               * 搬送業者一覧取得を行う。
               * @returns OK
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods52['get']['resBody'], BasicHeaders, Methods52['get']['status']>(prefix, `${prefix2}${PATH36}`, GET, option).json().then(r => r.body),
              /**
               * 搬送業者の追加を行う。
               * @returns 搬送業者登録成功
               */
              post: (option: { body: Methods52['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods52['post']['resBody'], BasicHeaders, Methods52['post']['status']>(prefix, `${prefix2}${PATH36}`, POST, option).json(),
              /**
               * 搬送業者の追加を行う。
               * @returns 搬送業者登録成功
               */
              $post: (option: { body: Methods52['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods52['post']['resBody'], BasicHeaders, Methods52['post']['status']>(prefix, `${prefix2}${PATH36}`, POST, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH36}`
            },
            halls: {
              /**
               * ホール一覧（業務画面用）取得を行う。
               * @returns OK
               */
              get: (option?: { query?: Methods54['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                fetch<Methods54['get']['resBody'], BasicHeaders, Methods54['get']['status']>(prefix, `${prefix2}${PATH37}`, GET, option).json(),
              /**
               * ホール一覧（業務画面用）取得を行う。
               * @returns OK
               */
              $get: (option?: { query?: Methods54['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                fetch<Methods54['get']['resBody'], BasicHeaders, Methods54['get']['status']>(prefix, `${prefix2}${PATH37}`, GET, option).json().then(r => r.body),
              $path: (option?: { method?: 'get' | undefined; query: Methods54['get']['query'] } | undefined) =>
                `${prefix}${prefix2}${PATH37}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
            },
            japan_agriculturals: {
              _japan_agricultural_id: (val4: number) => {
                const prefix4 = `${prefix2}${PATH38}/${val4}`

                return {
                  japan_agricultural_branches: {
                    /**
                     * JA支店一覧（業務画面用）取得を行う。
                     * @returns OK
                     */
                    get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<Methods56['get']['resBody'], BasicHeaders, Methods56['get']['status']>(prefix, `${prefix4}${PATH39}`, GET, option).json(),
                    /**
                     * JA支店一覧（業務画面用）取得を行う。
                     * @returns OK
                     */
                    $get: (option?: { config?: T | undefined } | undefined) =>
                      fetch<Methods56['get']['resBody'], BasicHeaders, Methods56['get']['status']>(prefix, `${prefix4}${PATH39}`, GET, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix4}${PATH39}`
                  }
                }
              },
              /**
               * JA一覧（業務画面用）取得を行う。
               * @returns OK
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods55['get']['resBody'], BasicHeaders, Methods55['get']['status']>(prefix, `${prefix2}${PATH38}`, GET, option).json(),
              /**
               * JA一覧（業務画面用）取得を行う。
               * @returns OK
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods55['get']['resBody'], BasicHeaders, Methods55['get']['status']>(prefix, `${prefix2}${PATH38}`, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH38}`
            },
            manuals: {
              _manual_id: (val4: number) => {
                const prefix4 = `${prefix2}${PATH40}/${val4}`

                return {
                  /**
                   * マニュアル削除を行う。
                   */
                  delete: (option?: { config?: T | undefined } | undefined) =>
                    fetch<void, BasicHeaders, Methods58['delete']['status']>(prefix, prefix4, DELETE, option).send(),
                  /**
                   * マニュアル削除を行う。
                   */
                  $delete: (option?: { config?: T | undefined } | undefined) =>
                    fetch<void, BasicHeaders, Methods58['delete']['status']>(prefix, prefix4, DELETE, option).send().then(r => r.body),
                  $path: () => `${prefix}${prefix4}`
                }
              },
              /**
               * マニュアル一覧を行う。
               * @returns マニュアル一覧成功
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods57['get']['resBody'], BasicHeaders, Methods57['get']['status']>(prefix, `${prefix2}${PATH40}`, GET, option).json(),
              /**
               * マニュアル一覧を行う。
               * @returns マニュアル一覧成功
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods57['get']['resBody'], BasicHeaders, Methods57['get']['status']>(prefix, `${prefix2}${PATH40}`, GET, option).json().then(r => r.body),
              /**
               * マニュアル追加を行う。
               */
              post: (option: { body: Methods57['post']['reqBody'], config?: T | undefined }) =>
                fetch<void, BasicHeaders, Methods57['post']['status']>(prefix, `${prefix2}${PATH40}`, POST, option).send(),
              /**
               * マニュアル追加を行う。
               */
              $post: (option: { body: Methods57['post']['reqBody'], config?: T | undefined }) =>
                fetch<void, BasicHeaders, Methods57['post']['status']>(prefix, `${prefix2}${PATH40}`, POST, option).send().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH40}`
            },
            mortuaries: {
              /**
               * 安置先一覧（業務画面用）取得を行う。画面は、該当エリアが指定された場合はdeceased_addressを付与してリクエストする。該当地区が指定された場合はdeceased_addressを未指定にする。
               * @returns OK
               */
              get: (option?: { query?: Methods59['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                fetch<Methods59['get']['resBody'], BasicHeaders, Methods59['get']['status']>(prefix, `${prefix2}${PATH23}`, GET, option).json(),
              /**
               * 安置先一覧（業務画面用）取得を行う。画面は、該当エリアが指定された場合はdeceased_addressを付与してリクエストする。該当地区が指定された場合はdeceased_addressを未指定にする。
               * @returns OK
               */
              $get: (option?: { query?: Methods59['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                fetch<Methods59['get']['resBody'], BasicHeaders, Methods59['get']['status']>(prefix, `${prefix2}${PATH23}`, GET, option).json().then(r => r.body),
              $path: (option?: { method?: 'get' | undefined; query: Methods59['get']['query'] } | undefined) =>
                `${prefix}${prefix2}${PATH23}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
            },
            phone_line_partners: {
              _phone_line_partner_id: (val4: number) => {
                const prefix4 = `${prefix2}${PATH41}/${val4}`

                return {
                  /**
                   * 提携業者取得する
                   * @returns OK
                   */
                  get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods61['get']['resBody'], BasicHeaders, Methods61['get']['status']>(prefix, prefix4, GET, option).json(),
                  /**
                   * 提携業者取得する
                   * @returns OK
                   */
                  $get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods61['get']['resBody'], BasicHeaders, Methods61['get']['status']>(prefix, prefix4, GET, option).json().then(r => r.body),
                  /**
                   * 提携業者更新する
                   * @param option.body - 提携業者更新時情報
                   */
                  put: (option: { body: Methods61['put']['reqBody'], config?: T | undefined }) =>
                    fetch<void, BasicHeaders, Methods61['put']['status']>(prefix, prefix4, PUT, option).send(),
                  /**
                   * 提携業者更新する
                   * @param option.body - 提携業者更新時情報
                   */
                  $put: (option: { body: Methods61['put']['reqBody'], config?: T | undefined }) =>
                    fetch<void, BasicHeaders, Methods61['put']['status']>(prefix, prefix4, PUT, option).send().then(r => r.body),
                  /**
                   * 提携業者削除を行う。
                   */
                  delete: (option?: { config?: T | undefined } | undefined) =>
                    fetch<void, BasicHeaders, Methods61['delete']['status']>(prefix, prefix4, DELETE, option).send(),
                  /**
                   * 提携業者削除を行う。
                   */
                  $delete: (option?: { config?: T | undefined } | undefined) =>
                    fetch<void, BasicHeaders, Methods61['delete']['status']>(prefix, prefix4, DELETE, option).send().then(r => r.body),
                  $path: () => `${prefix}${prefix4}`
                }
              },
              /**
               * 提携業者一覧を行う。
               * @returns 提携業者一覧成功
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods60['get']['resBody'], BasicHeaders, Methods60['get']['status']>(prefix, `${prefix2}${PATH41}`, GET, option).json(),
              /**
               * 提携業者一覧を行う。
               * @returns 提携業者一覧成功
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods60['get']['resBody'], BasicHeaders, Methods60['get']['status']>(prefix, `${prefix2}${PATH41}`, GET, option).json().then(r => r.body),
              /**
               * 提携業者追加を行う。
               */
              post: (option: { body: Methods60['post']['reqBody'], config?: T | undefined }) =>
                fetch<void, BasicHeaders, Methods60['post']['status']>(prefix, `${prefix2}${PATH41}`, POST, option).send(),
              /**
               * 提携業者追加を行う。
               */
              $post: (option: { body: Methods60['post']['reqBody'], config?: T | undefined }) =>
                fetch<void, BasicHeaders, Methods60['post']['status']>(prefix, `${prefix2}${PATH41}`, POST, option).send().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH41}`
            },
            report_destinations: {
              _report_destination_id: (val4: number) => {
                const prefix4 = `${prefix2}${PATH42}/${val4}`

                return {
                  /**
                   * 報告先取得する
                   * @returns OK
                   */
                  get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods63['get']['resBody'], BasicHeaders, Methods63['get']['status']>(prefix, prefix4, GET, option).json(),
                  /**
                   * 報告先取得する
                   * @returns OK
                   */
                  $get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods63['get']['resBody'], BasicHeaders, Methods63['get']['status']>(prefix, prefix4, GET, option).json().then(r => r.body),
                  /**
                   * 報告先更新する
                   * @param option.body - 報告先更新時情報
                   */
                  put: (option: { body: Methods63['put']['reqBody'], config?: T | undefined }) =>
                    fetch<void, BasicHeaders, Methods63['put']['status']>(prefix, prefix4, PUT, option).send(),
                  /**
                   * 報告先更新する
                   * @param option.body - 報告先更新時情報
                   */
                  $put: (option: { body: Methods63['put']['reqBody'], config?: T | undefined }) =>
                    fetch<void, BasicHeaders, Methods63['put']['status']>(prefix, prefix4, PUT, option).send().then(r => r.body),
                  /**
                   * 報告先削除を行う。
                   */
                  delete: (option?: { config?: T | undefined } | undefined) =>
                    fetch<void, BasicHeaders, Methods63['delete']['status']>(prefix, prefix4, DELETE, option).send(),
                  /**
                   * 報告先削除を行う。
                   */
                  $delete: (option?: { config?: T | undefined } | undefined) =>
                    fetch<void, BasicHeaders, Methods63['delete']['status']>(prefix, prefix4, DELETE, option).send().then(r => r.body),
                  $path: () => `${prefix}${prefix4}`
                }
              },
              /**
               * 報告先一覧を行う。
               * @returns 報告先一覧成功
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods62['get']['resBody'], BasicHeaders, Methods62['get']['status']>(prefix, `${prefix2}${PATH42}`, GET, option).json(),
              /**
               * 報告先一覧を行う。
               * @returns 報告先一覧成功
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods62['get']['resBody'], BasicHeaders, Methods62['get']['status']>(prefix, `${prefix2}${PATH42}`, GET, option).json().then(r => r.body),
              /**
               * 報告先追加を行う。
               */
              post: (option: { body: Methods62['post']['reqBody'], config?: T | undefined }) =>
                fetch<void, BasicHeaders, Methods62['post']['status']>(prefix, `${prefix2}${PATH42}`, POST, option).send(),
              /**
               * 報告先追加を行う。
               */
              $post: (option: { body: Methods62['post']['reqBody'], config?: T | undefined }) =>
                fetch<void, BasicHeaders, Methods62['post']['status']>(prefix, `${prefix2}${PATH42}`, POST, option).send().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH42}`
            },
            stores: {
              /**
               * 回線配下店舗一覧（業務画面用）取得を行う。
               * @returns OK
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods64['get']['resBody'], BasicHeaders, Methods64['get']['status']>(prefix, `${prefix2}${PATH24}`, GET, option).json(),
              /**
               * 回線配下店舗一覧（業務画面用）取得を行う。
               * @returns OK
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods64['get']['resBody'], BasicHeaders, Methods64['get']['status']>(prefix, `${prefix2}${PATH24}`, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH24}`
            },
            /**
             * 回線の取得を行う。
             * @returns 回線取得成功
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods43['get']['resBody'], BasicHeaders, Methods43['get']['status']>(prefix, prefix2, GET, option).json(),
            /**
             * 回線の取得を行う。
             * @returns 回線取得成功
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods43['get']['resBody'], BasicHeaders, Methods43['get']['status']>(prefix, prefix2, GET, option).json().then(r => r.body),
            /**
             * 回線の更新を行う。
             */
            put: (option: { body: Methods43['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods43['put']['status']>(prefix, prefix2, PUT, option).send(),
            /**
             * 回線の更新を行う。
             */
            $put: (option: { body: Methods43['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods43['put']['status']>(prefix, prefix2, PUT, option).send().then(r => r.body),
            /**
             * 回線の削除を行う。
             */
            delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<void, BasicHeaders, Methods43['delete']['status']>(prefix, prefix2, DELETE, option).send(),
            /**
             * 回線の削除を行う。
             */
            $delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<void, BasicHeaders, Methods43['delete']['status']>(prefix, prefix2, DELETE, option).send().then(r => r.body),
            $path: () => `${prefix}${prefix2}`
          }
        },
        /**
         * 回線一覧取得を行う。
         * @returns OK
         */
        get: (option: { query: Methods42['get']['query'], config?: T | undefined }) =>
          fetch<Methods42['get']['resBody'], BasicHeaders, Methods42['get']['status']>(prefix, PATH30, GET, option).json(),
        /**
         * 回線一覧取得を行う。
         * @returns OK
         */
        $get: (option: { query: Methods42['get']['query'], config?: T | undefined }) =>
          fetch<Methods42['get']['resBody'], BasicHeaders, Methods42['get']['status']>(prefix, PATH30, GET, option).json().then(r => r.body),
        /**
         * 回線の追加を行う。
         * @returns 回線登録成功
         */
        post: (option: { body: Methods42['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods42['post']['resBody'], BasicHeaders, Methods42['post']['status']>(prefix, PATH30, POST, option).json(),
        /**
         * 回線の追加を行う。
         * @returns 回線登録成功
         */
        $post: (option: { body: Methods42['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods42['post']['resBody'], BasicHeaders, Methods42['post']['status']>(prefix, PATH30, POST, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods42['get']['query'] } | undefined) =>
          `${prefix}${PATH30}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
      },
      regions: {
        _region_id: (val2: number) => {
          const prefix2 = `${PATH43}/${val2}`

          return {
            /**
             * 地区の取得を行う。
             * @returns 地区取得成功
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods66['get']['resBody'], BasicHeaders, Methods66['get']['status']>(prefix, prefix2, GET, option).json(),
            /**
             * 地区の取得を行う。
             * @returns 地区取得成功
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods66['get']['resBody'], BasicHeaders, Methods66['get']['status']>(prefix, prefix2, GET, option).json().then(r => r.body),
            /**
             * 地区の更新を行う。
             */
            put: (option: { body: Methods66['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods66['put']['status']>(prefix, prefix2, PUT, option).send(),
            /**
             * 地区の更新を行う。
             */
            $put: (option: { body: Methods66['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods66['put']['status']>(prefix, prefix2, PUT, option).send().then(r => r.body),
            /**
             * 地区の削除を行う。
             */
            delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<void, BasicHeaders, Methods66['delete']['status']>(prefix, prefix2, DELETE, option).send(),
            /**
             * 地区の削除を行う。
             */
            $delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<void, BasicHeaders, Methods66['delete']['status']>(prefix, prefix2, DELETE, option).send().then(r => r.body),
            $path: () => `${prefix}${prefix2}`
          }
        },
        /**
         * 地区一覧取得を行う。
         * @returns OK
         */
        get: (option: { query: Methods65['get']['query'], config?: T | undefined }) =>
          fetch<Methods65['get']['resBody'], BasicHeaders, Methods65['get']['status']>(prefix, PATH43, GET, option).json(),
        /**
         * 地区一覧取得を行う。
         * @returns OK
         */
        $get: (option: { query: Methods65['get']['query'], config?: T | undefined }) =>
          fetch<Methods65['get']['resBody'], BasicHeaders, Methods65['get']['status']>(prefix, PATH43, GET, option).json().then(r => r.body),
        /**
         * 地区の追加を行う。
         * @returns 地区登録成功
         */
        post: (option: { body: Methods65['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods65['post']['resBody'], BasicHeaders, Methods65['post']['status']>(prefix, PATH43, POST, option).json(),
        /**
         * 地区の追加を行う。
         * @returns 地区登録成功
         */
        $post: (option: { body: Methods65['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods65['post']['resBody'], BasicHeaders, Methods65['post']['status']>(prefix, PATH43, POST, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods65['get']['query'] } | undefined) =>
          `${prefix}${PATH43}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
      },
      stores: {
        _store_id: (val2: number) => {
          const prefix2 = `${PATH44}/${val2}`

          return {
            /**
             * 店舗の取得を行う。
             * @returns 店舗取得成功
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods68['get']['resBody'], BasicHeaders, Methods68['get']['status']>(prefix, prefix2, GET, option).json(),
            /**
             * 店舗の取得を行う。
             * @returns 店舗取得成功
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods68['get']['resBody'], BasicHeaders, Methods68['get']['status']>(prefix, prefix2, GET, option).json().then(r => r.body),
            /**
             * 店舗の更新を行う。
             */
            put: (option: { body: Methods68['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods68['put']['status']>(prefix, prefix2, PUT, option).send(),
            /**
             * 店舗の更新を行う。
             */
            $put: (option: { body: Methods68['put']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods68['put']['status']>(prefix, prefix2, PUT, option).send().then(r => r.body),
            /**
             * 店舗の削除を行う。
             */
            delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<void, BasicHeaders, Methods68['delete']['status']>(prefix, prefix2, DELETE, option).send(),
            /**
             * 店舗の削除を行う。
             */
            $delete: (option?: { config?: T | undefined } | undefined) =>
              fetch<void, BasicHeaders, Methods68['delete']['status']>(prefix, prefix2, DELETE, option).send().then(r => r.body),
            $path: () => `${prefix}${prefix2}`
          }
        },
        /**
         * 店舗一覧取得を行う。
         * @returns OK
         */
        get: (option: { query: Methods67['get']['query'], config?: T | undefined }) =>
          fetch<Methods67['get']['resBody'], BasicHeaders, Methods67['get']['status']>(prefix, PATH44, GET, option).json(),
        /**
         * 店舗一覧取得を行う。
         * @returns OK
         */
        $get: (option: { query: Methods67['get']['query'], config?: T | undefined }) =>
          fetch<Methods67['get']['resBody'], BasicHeaders, Methods67['get']['status']>(prefix, PATH44, GET, option).json().then(r => r.body),
        /**
         * 店舗の追加を行う。
         * @returns 店舗登録成功
         */
        post: (option: { body: Methods67['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods67['post']['resBody'], BasicHeaders, Methods67['post']['status']>(prefix, PATH44, POST, option).json(),
        /**
         * 店舗の追加を行う。
         * @returns 店舗登録成功
         */
        $post: (option: { body: Methods67['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods67['post']['resBody'], BasicHeaders, Methods67['post']['status']>(prefix, PATH44, POST, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods67['get']['query'] } | undefined) =>
          `${prefix}${PATH44}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
      }
    },
    pdf: {
      tasks: {
        _task_id: (val2: number) => {
          const prefix2 = `${PATH45}/${val2}`

          return {
            /**
             * 業務の印刷を行う
             * @returns 印刷成功
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods69['get']['resBody'], BasicHeaders, Methods69['get']['status']>(prefix, prefix2, GET, option).blob(),
            /**
             * 業務の印刷を行う
             * @returns 印刷成功
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods69['get']['resBody'], BasicHeaders, Methods69['get']['status']>(prefix, prefix2, GET, option).blob().then(r => r.body),
            $path: () => `${prefix}${prefix2}`
          }
        }
      }
    },
    tasks: {
      _task_id: (val1: number) => {
        const prefix1 = `${PATH46}/${val1}`

        return {
          /**
           * 業務取得する
           * @returns OK
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods71['get']['resBody'], BasicHeaders, Methods71['get']['status']>(prefix, prefix1, GET, option).json(),
          /**
           * 業務取得する
           * @returns OK
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods71['get']['resBody'], BasicHeaders, Methods71['get']['status']>(prefix, prefix1, GET, option).json().then(r => r.body),
          /**
           * 業務更新する
           * @param option.body - 業務更新時情報
           */
          put: (option: { body: Methods71['put']['reqBody'], config?: T | undefined }) =>
            fetch<void, BasicHeaders, Methods71['put']['status']>(prefix, prefix1, PUT, option).send(),
          /**
           * 業務更新する
           * @param option.body - 業務更新時情報
           */
          $put: (option: { body: Methods71['put']['reqBody'], config?: T | undefined }) =>
            fetch<void, BasicHeaders, Methods71['put']['status']>(prefix, prefix1, PUT, option).send().then(r => r.body),
          /**
           * 業務削除する
           */
          delete: (option?: { config?: T | undefined } | undefined) =>
            fetch<void, BasicHeaders, Methods71['delete']['status']>(prefix, prefix1, DELETE, option).send(),
          /**
           * 業務削除する
           */
          $delete: (option?: { config?: T | undefined } | undefined) =>
            fetch<void, BasicHeaders, Methods71['delete']['status']>(prefix, prefix1, DELETE, option).send().then(r => r.body),
          $path: () => `${prefix}${prefix1}`
        }
      },
      /**
       * 業務一覧取得を行う。
       * @returns OK
       */
      get: (option: { query: Methods70['get']['query'], config?: T | undefined }) =>
        fetch<Methods70['get']['resBody'], BasicHeaders, Methods70['get']['status']>(prefix, PATH46, GET, option).json(),
      /**
       * 業務一覧取得を行う。
       * @returns OK
       */
      $get: (option: { query: Methods70['get']['query'], config?: T | undefined }) =>
        fetch<Methods70['get']['resBody'], BasicHeaders, Methods70['get']['status']>(prefix, PATH46, GET, option).json().then(r => r.body),
      /**
       * 業務追加する
       * @param option.body - 業務追加時情報
       * @returns Created
       */
      post: (option: { body: Methods70['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods70['post']['resBody'], BasicHeaders, Methods70['post']['status']>(prefix, PATH46, POST, option).json(),
      /**
       * 業務追加する
       * @param option.body - 業務追加時情報
       * @returns Created
       */
      $post: (option: { body: Methods70['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods70['post']['resBody'], BasicHeaders, Methods70['post']['status']>(prefix, PATH46, POST, option).json().then(r => r.body),
      $path: (option?: { method?: 'get' | undefined; query: Methods70['get']['query'] } | undefined) =>
        `${prefix}${PATH46}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
    },
    user: {
      /**
       * 認証済みのユーザー情報を取得
       * @returns logged in
       */
      get: (option?: { config?: T | undefined } | undefined) =>
        fetch<Methods72['get']['resBody'], BasicHeaders, Methods72['get']['status']>(prefix, PATH47, GET, option).json(),
      /**
       * 認証済みのユーザー情報を取得
       * @returns logged in
       */
      $get: (option?: { config?: T | undefined } | undefined) =>
        fetch<Methods72['get']['resBody'], BasicHeaders, Methods72['get']['status']>(prefix, PATH47, GET, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH47}`
    },
    user_roles: {
      /**
       * ユーザロール一覧取得を行う。
       * @returns OK
       */
      get: (option?: { config?: T | undefined } | undefined) =>
        fetch<Methods73['get']['resBody'], BasicHeaders, Methods73['get']['status']>(prefix, PATH48, GET, option).json(),
      /**
       * ユーザロール一覧取得を行う。
       * @returns OK
       */
      $get: (option?: { config?: T | undefined } | undefined) =>
        fetch<Methods73['get']['resBody'], BasicHeaders, Methods73['get']['status']>(prefix, PATH48, GET, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH48}`
    },
    users: {
      _user_id: (val1: number) => {
        const prefix1 = `${PATH49}/${val1}`

        return {
          /**
           * ユーザの編集を行う
           */
          put: (option: { body: Methods75['put']['reqBody'], config?: T | undefined }) =>
            fetch<void, BasicHeaders, Methods75['put']['status']>(prefix, prefix1, PUT, option).send(),
          /**
           * ユーザの編集を行う
           */
          $put: (option: { body: Methods75['put']['reqBody'], config?: T | undefined }) =>
            fetch<void, BasicHeaders, Methods75['put']['status']>(prefix, prefix1, PUT, option).send().then(r => r.body),
          /**
           * ユーザ詳細取得を行う
           * @returns 詳細取得成功
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods75['get']['resBody'], BasicHeaders, Methods75['get']['status']>(prefix, prefix1, GET, option).json(),
          /**
           * ユーザ詳細取得を行う
           * @returns 詳細取得成功
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods75['get']['resBody'], BasicHeaders, Methods75['get']['status']>(prefix, prefix1, GET, option).json().then(r => r.body),
          /**
           * ユーザの削除を行う
           */
          delete: (option?: { config?: T | undefined } | undefined) =>
            fetch<void, BasicHeaders, Methods75['delete']['status']>(prefix, prefix1, DELETE, option).send(),
          /**
           * ユーザの削除を行う
           */
          $delete: (option?: { config?: T | undefined } | undefined) =>
            fetch<void, BasicHeaders, Methods75['delete']['status']>(prefix, prefix1, DELETE, option).send().then(r => r.body),
          $path: () => `${prefix}${prefix1}`
        }
      },
      /**
       * ユーザの登録を行う
       * @returns Created
       */
      post: (option: { body: Methods74['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods74['post']['resBody'], BasicHeaders, Methods74['post']['status']>(prefix, PATH49, POST, option).json(),
      /**
       * ユーザの登録を行う
       * @returns Created
       */
      $post: (option: { body: Methods74['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods74['post']['resBody'], BasicHeaders, Methods74['post']['status']>(prefix, PATH49, POST, option).json().then(r => r.body),
      /**
       * ユーザ一覧取得を行う。
       * @returns OK
       */
      get: (option: { query: Methods74['get']['query'], config?: T | undefined }) =>
        fetch<Methods74['get']['resBody'], BasicHeaders, Methods74['get']['status']>(prefix, PATH49, GET, option).json(),
      /**
       * ユーザ一覧取得を行う。
       * @returns OK
       */
      $get: (option: { query: Methods74['get']['query'], config?: T | undefined }) =>
        fetch<Methods74['get']['resBody'], BasicHeaders, Methods74['get']['status']>(prefix, PATH49, GET, option).json().then(r => r.body),
      $path: (option?: { method?: 'get' | undefined; query: Methods74['get']['query'] } | undefined) =>
        `${prefix}${PATH49}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
    }
  }
}

export type ApiInstance = ReturnType<typeof api>
export default api
