import aspida from '@aspida/axios'
import axios from 'axios'
import dayjs from 'dayjs'
import router from 'next/router'
import { parseCookies } from 'nookies'

import api from '@/generated/api/$api'

export const cookieExpires = {
  expires: dayjs().add(7, 'd').toDate(),
  path: '/',
}

export const cookieErrorMessageExpires = {
  expires: dayjs().add(1, 'minute').toDate(),
  path: '/',
}

export const headerConfig = (token?: string) => {
  return {
    baseURL: process.env.NEXT_PUBLIC_API_URL,
    headers: token
      ? {
          Authorization: `Bearer ${token}`,
        }
      : undefined,
  }
}

const checkToken = () => {
  if (!parseCookies().token) {
    client.setToken('')
    router.push('/account/sign-in')
  }
}

class Client {
  private static instance: Client
  private token?: string

  constructor() {
    typeof window !== 'undefined' && window.addEventListener('focus', checkToken)
  }

  public static getInstance() {
    return this.instance || (this.instance = new this())
  }

  setToken(token: string) {
    this.token = token
  }

  deleteToken() {
    this.token = undefined
  }

  api() {
    return api({
      ...aspida(axios, headerConfig(this.token || parseCookies()['token'])),
    })
  }

  destroy() {
    window.removeEventListener('focus', checkToken)
  }
}

export const client = new Client()
