import { css, Typography } from '@mui/material'
import React from 'react'

type Props = {}

const MwFooter: React.FC<Props> = () => {
  return (
    <footer
      css={css`
        margin: 4rem auto;
        width: 100%;
      `}
    >
      <Typography variant="body2" color="text.secondary" align="center">
        {'Copyright © harada '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    </footer>
  )
}

export default MwFooter
