export const PARTNER_ID_KEY = 'partnerId'
export const CENTER_ID_KEY = 'CenterId'
export const PHONE_LINE_ID_KEY = 'phoneLineId'
export const CARRIER_ID_KEY = 'carrierId'
export const PHONE_LINE_PARTNER_ID_KEY = 'phoneLinePartnerId'
export const STORE_ID_KEY = 'storeId'
export const AREA_ID_KEY = 'areaId'
export const REGION_ID_KEY = 'regionId'
export const FUNERAL_HALL_ID_KEY = 'funeral_hall_id'
export const WAKE_HALL_ID_KEY = 'wake_hall_id'
export const JA_ID_KEY = 'jaId'
export const JA_BRANCH_ID_KEY = 'jaBranchId'
export const TASKS_CATEGORY_ID_KEY = 'tasksCategoryId'
export const MORTUARY_ID_KEY = 'mortuaryId'
export const REPORT_DESTINATION_ID_KEY = 'reportDestinationId'
export const USERS_ID_KEY = 'usersId'
export const IN_COMING_ID_KEY = 'incomingId'
export const OUT_GOING_ID_KEY = 'outgoingId'
export const HOSPITAL_ID_KEY = 'hospitalId'
export const FUNERAL_PARTNER_ID_KEY = 'funeralPartnerId'
export const RELIGION_ID_KEY = 'religionId'
export const JA_GROUP_ID_KEY = 'jaGroupId'
export const JA_SEARCH_ID_KEY = 'jaSearchId'
export const JA_BRANCH_SEARCH_ID_KEY = 'jaBranchSearchId'

export const HOSPITAL_SELECTED_INFO = 'HOSPITAL_SELECTED_INFO'

export type SelectKeyType =
  | typeof PARTNER_ID_KEY
  | typeof CARRIER_ID_KEY
  | typeof PHONE_LINE_ID_KEY
  | typeof STORE_ID_KEY
  | typeof FUNERAL_HALL_ID_KEY
  | typeof WAKE_HALL_ID_KEY
  | typeof JA_ID_KEY
  | typeof JA_BRANCH_ID_KEY
  | typeof TASKS_CATEGORY_ID_KEY
  | typeof PHONE_LINE_PARTNER_ID_KEY
  | typeof MORTUARY_ID_KEY
  | typeof REPORT_DESTINATION_ID_KEY
  | typeof USERS_ID_KEY
  | typeof IN_COMING_ID_KEY
  | typeof OUT_GOING_ID_KEY
  | typeof HOSPITAL_ID_KEY
  | typeof FUNERAL_PARTNER_ID_KEY
  | typeof RELIGION_ID_KEY
  | typeof CENTER_ID_KEY
  | typeof REGION_ID_KEY
  | typeof HOSPITAL_SELECTED_INFO
  | string
