import { useCallback } from 'react'
import { useQuery, useQueryClient } from 'react-query'

import { snackbarResource } from '../const/resource'

export type SnackbarType =
  | {
      severity?: 'success' | 'info' | 'warning' | 'error'
      message?: string
      open?: boolean
    }
  | undefined

export const useSnackbar = () => {
  const queryClient = useQueryClient()
  const { data: query } = useQuery(snackbarResource.key, () =>
    queryClient.getQueryData<SnackbarType>('Snackbar')
  )

  const setQuery = useCallback(
    (value?: SnackbarType) => queryClient.setQueryData(snackbarResource.key, value),
    [queryClient]
  )

  return {
    data: query,
    setQuery, //TODO: change name
  }
}
