import { useAspidaQuery } from '@aspida/react-query'
import { useState } from 'react'

import { User } from '@/generated/api/@types'
import { client } from '@/src/config/clientApi'

export const useLoginUser = () => {
  const [loginUser, setLoginUser] = useState<User>()
  const { isLoading } = useAspidaQuery(client.api().user, '$get', {
    onSuccess: (data) => {
      setLoginUser(data)
    },
  })
  return {
    loginUser,
    isLoadingLoginUser: isLoading,
  }
}
