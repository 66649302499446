import MenuIcon from '@mui/icons-material/Menu'
import { css, Grid, Slide, Toolbar, useScrollTrigger } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import React, { useEffect } from 'react'
import { useQuery } from 'react-query'

import { User } from '@/generated/api/@types'
import { userIdResource } from '@/src/const/resource'
import { PARTNER_ID_KEY } from '@/src/const/selectKeys'
import { EUserPermission } from '@/src/const/userPermission'
import { useSelectId } from '@/src/hooks/select/useSelectId'

type Props = {
  user?: User
  drawerWidth: number
  isMaxWidth: boolean
  handleDrawerToggle: () => void
}

const styles = {
  container: css`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `,
}

const HideOnScroll: React.FC<{ window?: () => Window; children: React.ReactElement }> = (props) => {
  const { children, window } = props

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  })

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

const MwTopNav: React.FC<Props> = ({ isMaxWidth, drawerWidth, handleDrawerToggle }) => {
  const { data } = useQuery(userIdResource.key, userIdResource.fetcher)
  const { setQuery } = useSelectId(PARTNER_ID_KEY)
  const { data: userInfo } = useQuery(userIdResource.key, userIdResource.fetcher)
  useEffect(() => {
    if (userInfo.user_role.edit_masters !== EUserPermission.READ_WRITE) {
      setQuery(userInfo.partner_id)
    }
  }, [setQuery, userInfo.partner_id, userInfo.user_role.edit_masters])

  return (
    <HideOnScroll>
      <AppBar
        position="fixed"
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <div css={styles.container}>
            {!isMaxWidth && <Typography variant="h6">葬祭業務支援システム</Typography>}
            <Grid>
              <Typography variant="h6" color={'white'} textAlign="center">
                {`${data?.login_name}`}
              </Typography>
              <Typography variant="h6" color={'white'}>
                {`${data?.user_name} さん`}
              </Typography>
            </Grid>
          </div>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  )
}

export default MwTopNav
