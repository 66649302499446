import { useCallback, useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'

export const useSelectId = <T extends any>(
  key: string,
  id?: string | number | Array<any>,
  tryRefetch?: boolean
) => {
  const queryClient = useQueryClient()
  const [refetch, setRefetch] = useState(false)
  const { data } = useQuery(
    key,
    () => queryClient.getQueryData<T | string | number | null | undefined>(key),
    {
      enabled: !!refetch || !tryRefetch,
      cacheTime: 0,
      staleTime: Infinity,
      retryOnMount: true,
    }
  )
  useEffect(() => {
    if (id && (!refetch || !tryRefetch)) {
      queryClient.setQueryData(key, id)
      setRefetch(true)
    }
  }, [id, key, queryClient, refetch, tryRefetch])

  const setQuery = useCallback(
    (value?: T | string | number | null | undefined) => {
      setRefetch(true)
      queryClient.setQueryData(key, value)
    },
    [key, queryClient]
  )
  return {
    id: data as T,
    setQuery,
  }
}
