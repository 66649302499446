import { MutationCache, QueryCache, QueryClient } from 'react-query'

export const ERROR_HANDLE_KEY = 'errorHandle'

const queryClientConfig = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 10,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
  queryCache: new QueryCache({
    onSuccess: () => {
      queryClientConfig.setQueryData(ERROR_HANDLE_KEY, '')
    },
    onError: (error, query) => {
      queryClientConfig.setQueryData(ERROR_HANDLE_KEY, error)
    },
  }),
  mutationCache: new MutationCache({
    onSuccess: () => {
      queryClientConfig.setQueryData(ERROR_HANDLE_KEY, '')
    },
    onError: (error) => {
      queryClientConfig.setQueryData(ERROR_HANDLE_KEY, error)
    },
  }),
})

export default queryClientConfig
