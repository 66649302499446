import { css, Snackbar } from '@mui/material'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import React, { useCallback, useEffect, useState } from 'react'

import { useSnackbar } from '@/src/hooks/useSnackbar'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const MwSnackbar: React.FC = () => {
  const [open, setOpen] = useState(false)
  const { data, setQuery } = useSnackbar()

  const handleClose = useCallback(() => {
    setOpen(false)
    setQuery({ ...data, message: '' })
  }, [data, setQuery])

  useEffect(() => {
    if (!data?.message) return
    setOpen(true)
  }, [data])

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      autoHideDuration={5000}
      css={css`
        min-width: 300px;
      `}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={data?.severity || 'info'} sx={{ width: '100%' }}>
        {data?.message}
      </Alert>
    </Snackbar>
  )
}

export default MwSnackbar
